import React from "react";
import "../../scss/components/sections.scss";

const ItemDetailContainer = ({ topborder = false, nopadding = false, styles, children }) => {
  let containerClass = topborder ? "itemdetailcontainer top-border" : "itemdetailcontainer";
  containerClass = nopadding ? `${containerClass} no-padding` : containerClass;

  return (
    <div className={containerClass} style={{ ...styles }}>
      {children}
    </div>
  );
}

export default ItemDetailContainer;
