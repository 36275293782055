import React, { useState } from "react";
import BrokerEmploymentItem from "../../../../../../../components/listitems/detailitems/BrokerEmploymentItem";
import SingleCard from "../../../../../../../components/cards/SingleCard";
import Popup from "../../../../../../../components/widgets/Popup";
import DualColumn from "../../../../../../../components/containers/DualColumn";
import FancySubmit from "../../../../../../../components/buttons/FancySubmit";
import { removeEmploymentRecord } from "../../handlers/employmentHandlers";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

const EmploymentList = ({
  employments,
  dofaIndex,
  setEmployments = () => { },
  setDofaIndex = () => { },
  setDofaDate = () => { },
  setExperience = () => { }
}) => {
  const [indexOfEmploymentRecordToRemove, setIndexOfEmploymentRecordToRemove] = useState();
  const [removeEmploymentPrompt, setRemoveEmploymentPrompt] = useState(false);

  const handleEmploymentRecordRemovePrompt = (recordIndex) => {
    setRemoveEmploymentPrompt(true);
    setIndexOfEmploymentRecordToRemove(recordIndex);
  }

  const handleEmploymentRecordRemove = (recordIndex) => {
    const result = removeEmploymentRecord(employments, recordIndex);

    if (result) {
      setEmployments(result.newEmployments);
      setDofaIndex(result.dofaIndex);
      setDofaDate(result.dofaDate);
      setExperience(result.experience);
      setGaps(result.gaps);
    }

    setIndexOfEmploymentRecordToRemove(undefined);
    setRemoveEmploymentPrompt(false);
  }

  return (
    <>
      <div className='brokers-new-name-box brokers-new-name-box-bottom-margin'>
        <h4>Employment History</h4>
      </div>
      {
        employments?.length > 0 ? employments.map((employment, index) => (
          <BrokerEmploymentItem
            key={index}
            employment={employment}
            lastItem={employments.length - 1 === index}
            isDofa={index === dofaIndex}
            index={index}
            onDelete={handleEmploymentRecordRemovePrompt}
          />
        )) :
          <>
            <SingleCard icon={faBriefcase} value="No Record!" iconColor='#e57373' />
            <div style={{ marginBottom: 15 }} />
          </>
      }
      <Popup isLoading={removeEmploymentPrompt}>
        <p>Are you sure you wish to remove this Employment Record?</p>
        <DualColumn
          leftChildren={<FancySubmit title='Yes' onClick={() => handleEmploymentRecordRemove(indexOfEmploymentRecordToRemove)} />}
          rightChildren={<FancySubmit title='No' onClick={() => {
            setIndexOfEmploymentRecordToRemove(undefined);
            setRemoveEmploymentPrompt(false);
          }} />}
        />
      </Popup>
    </>
  );
};

export default EmploymentList;
