import React from 'react';

const MainHeader = ({ children }) => {
  return (
    <header className="full-height-section header-section">
      {children}
    </header>
  );
}

export default MainHeader;