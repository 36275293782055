import React from "react";
import "./broker-examination-item.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScroll, faCalendarCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const BrokerExaminationItem = ({ examination, index, lastItem = false, onDelete = (i) => console.log(i) }) => {
  var achievedDate = examination?.dateOfExamAchieved ? new Date(examination?.dateOfExamAchieved).toISOString().split('T')[0] : "";
  var containerStyle = lastItem ?
    "broker-employment-item-container broker-employment-item-container-bottom-margin" :
    "broker-employment-item-container";

  return (
    <div className={containerStyle}>
      <div className="broker-employment-item-icon-box">
        <FontAwesomeIcon icon={faScroll} className="broker-employment-item-icon-style" />
      </div>
      <div className="broker-employment-item-detail-box">
        <h4>{examination?.levelName ?? "Unknown Level"}</h4>
        <div className="broker-employment-item-history-box">
          <div className="broker-employment-item-date-box">
            <FontAwesomeIcon icon={faCalendarCheck} className="broker-employment-item-date-icon-style" />
            <p>{achievedDate}</p>
          </div>
        </div>
      </div>
      <div className="broker-employment-item-close-box" onClick={() => onDelete(index)}>
        <FontAwesomeIcon icon={faTrashAlt} className="broker-employment-item-close-icon-style" />
      </div>
    </div>
  );
}

export default BrokerExaminationItem;
