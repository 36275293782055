import React, { useState } from 'react';
import { DateTime } from 'luxon';
import StylishInput from '../../../../../../../components/formcomponents/inputs/StylishInput';
import StylishDateTimePicker from '../../../../../../../components/formcomponents/inputs/StylishDateTimePicker';
import FancySubmit from '../../../../../../../components/buttons/FancySubmit';
import { addQualificationRecord } from '../../handlers/qualificationHandlers';
import { faCalendarCheck, faInstitution, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import '../../brokers-new.scss';
import JellyCheckbox from '../../../../../../../components/formcomponents/checkboxes/JellyCheckbox';

const QualificationRecordForm = ({
  qualifications,
  setQualifications = () => { }
}) => {
  const [qualificationName, setQualificationName] = useState('');
  const [institution, setInstitution] = useState('');
  const [dateQualificationAchieved, setDateQualificationAchieved] = useState(DateTime.now().toJSDate());
  const [recognisedFsca, setRecognisedFsca] = useState(false);

  const handleQualificationNameChange = (event) => {
    setQualificationName(event.target.value);
  }

  const handleInstitutionChange = (event) => {
    setInstitution(event.target.value);
  }

  const handleQualificationDateChange = (newDate) => {
    setDateQualificationAchieved(newDate);
  }

  const handleRecognisedFscaChange = (event) => {
    setRecognisedFsca(event.target.checked);
  }

  const handleQualificationRecordAdd = () => {
    const result = addQualificationRecord(qualificationName, institution, dateQualificationAchieved, recognisedFsca, qualifications);
    if (result) {
      setQualifications(result.newQualifications);
      // Reset fields
      setQualificationName(result.resetFields.qualificationName);
      setInstitution(result.resetFields.institution);
      setDateQualificationAchieved(result.resetFields.achievedDate);
      setRecognisedFsca(result.resetFields.fscaRecognised);
    }
  }

  return (
    <>
      <div className='brokers-new-name-box'>
        <h4>Add Qualification:</h4>
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Qualification:</p>
        </div>
        <StylishInput
          value={qualificationName}
          onChange={handleQualificationNameChange}
          icon={faGraduationCap}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Institution:</p>
        </div>
        <StylishInput
          value={institution}
          onChange={handleInstitutionChange}
          icon={faInstitution}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Date Achieved:</p>
        </div>
        <StylishDateTimePicker
          date={dateQualificationAchieved}
          onSelect={() => { }}
          onChange={handleQualificationDateChange}
          icon={faCalendarCheck}
          groupStyles={{ width: 200 }}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>FSCA Recognised:</p>
        </div>
        <div style={{ width: 200, height: 45, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
          <JellyCheckbox id="qual-check" checked={recognisedFsca} onChange={handleRecognisedFscaChange} />
        </div>
      </div>
      <div className='brokers-new-name-box align-right'>
        <FancySubmit title='Add Record' onClick={handleQualificationRecordAdd} />
      </div>
    </>
  );
}

export default QualificationRecordForm;
