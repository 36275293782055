import React from "react";
import './jelly-check.scss';

const JellyCheckbox = ({ id, checked = false, label = "", onChange = () => {} }) => {
  return (
    <div className="cntr">
      <input checked={checked} type="checkbox" id={id} className="hidden-xs-up" onChange={onChange} />
      <label htmlFor={id} className="cbx">{label}</label>
    </div>
  );
}

export default JellyCheckbox;
