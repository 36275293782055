import React from "react";
import ItemDetailContainer from "../ItemDetailContainer";
import './main-content.scss';

const MainContent = ({ children }) => {
  return (
    <div className="main-content-container">
      <ItemDetailContainer>
        {children}
      </ItemDetailContainer>
    </div>
  );
}

export default MainContent;
