import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import '../../../../scss/components/sidebar.scss';
import { Outlet, Link } from 'react-router-dom';
import menuItems from '../../../../navigation/menuConfig';

const Dashboard = () => {
  const location = useLocation();

  const icons = {
    'Competency': faBriefcase,
    'Management': faUserFriends,
  };

  const getRootPath = (path) => {
    const splitPath = path.split('/');
    return splitPath.slice(0, 5).join('/');
  };

  const renderMenuItems = (items) => {
    return items.map((item, index) => {
      const isActive = getRootPath(location.pathname) === getRootPath(item.path);
      return (
        <li key={index}>
          <Link to={item.path} className={isActive ? 'active' : ''}>{item.label}</Link>
        </li>
      );
    });
  };

  const renderCategories = () => {
    return menuItems.map((menuCategory, index) => (
      <div style={{ width: "100%" }} key={index}>
        <h2><FontAwesomeIcon icon={icons[menuCategory.category]} style={{ color: "#ccc", marginRight: "10px" }} />{menuCategory.category}</h2>
        <ul>{renderMenuItems(menuCategory.items)}</ul>
      </div>
    ));
  };

  return (
    <div className="dashcontainer">
      <div className="sidebar">
        <h1>Aquila</h1>
        <div className="sidenav">{renderCategories()}</div>
      </div>
      <div className="dashdetail">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
