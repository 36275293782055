import ErrorHandling from '../../../../../../utils/errors/ErrorHandling';
import {
  isStartDateGreaterThanEndDate,
  calculateMonthDifference
} from '../../../../../../utils/datetime/dateTimeUtils';

export const addCobRecord = (
  cob,
  cobs,
  brokerDofa,
  dofaStart,
  dofaEnd,
  exams,
  qualifications,
  associations,
  lists
) => {
  try {
    if (!cob?.value) {
      return ErrorHandling.toastifyError("Select a Product Subcategory!");
    }
    if (!dofaStart) {
      return ErrorHandling.toastifyError("Set a valid DOFA date for the Product Subcategory!");
    }
    if (isStartDateGreaterThanEndDate(dofaStart)) {
      return ErrorHandling.toastifyError("DOFA date cannot be in the future!");
    }
    if (dofaEnd && isStartDateGreaterThanEndDate(dofaStart, dofaEnd)) {
      return ErrorHandling.toastifyError("DOFA start date cannot be after termination date!");
    }

    // Here we apply the business logic to COB:

    // Associations for this Product Subcategory:
    const applicableAssociations = associations.find((association) => association.parentListItem.id === cob.value)?.childListItems;

    const productTierListId = lists.find((list) => list.name === 'Product Tiers')?.id;
    const classOfBusinessRequiredListId = lists.find((list) => list.name === 'Class of Business Indicator')?.id;
    const qualificationLevelListId = lists.find((list) => list.name === 'Qualification Levels')?.id; // This one needs to be added to the model on the backend side.
    const advisoryExperienceListId = lists.find((list) => list.name === 'Advisory Experience')?.id;
    const intermediaryExperienceListId = lists.find((list) => list.name === 'Intermediary Experience')?.id;

    const productTierId = applicableAssociations.find((aa) => aa.listId === productTierListId)?.id;
    const classOfBusinessRequiredId = applicableAssociations.find((aa) => aa.listId === classOfBusinessRequiredListId)?.id;
    // The qualification level id must go in here...
    const minimumAdvisoryExperienceRequiredId = applicableAssociations.find((aa) => aa.listId === advisoryExperienceListId)?.id;
    const minimumIntermediaryExperienceRequiredId = applicableAssociations.find((aa) => aa.listId === intermediaryExperienceListId)?.id;

    const totalExperienceGained = dofaEnd ? calculateMonthDifference(dofaStart, dofaEnd) : calculateMonthDifference(dofaStart);

    const classOfBusinessList = lists.find((list) => list.name === 'Class of Business');
    const qualificationDeadlineList = lists.find((list) => list.name === 'Qualification Deadline');
    const regulatoryExamDeadlineList = lists.find((list) => list.name === 'RE5 Deadline');
    const sortedQualifications = [...qualifications].sort((a, b) => a.dateOfQualificationAchieved - b.dateOfQualificationAchieved);
    const firstRecognisedQualification = sortedQualifications.find(qualification => qualification.recognised);
    const re5ExamCompletion = exams.find((exam) => exam.levelName === 'RE 5');
    let classOfBusinessDeadline;
    let qualificationDeadline;
    let reExamDeadline;
    if (isStartDateGreaterThanEndDate(new Date("2007-12-31"), brokerDofa)) {
      classOfBusinessDeadline = classOfBusinessList?.listItems?.find((item) => item.name === 'Exempt');
      qualificationDeadline = qualificationDeadlineList?.listItems?.find((item) => item.name === '2009-12-31');
      reExamDeadline = regulatoryExamDeadlineList?.listItems?.find((item) => item.name === '2013-03-31');
    } else if (isStartDateGreaterThanEndDate(new Date("2009-12-31"), brokerDofa)) {
      classOfBusinessDeadline = classOfBusinessList?.listItems?.find((item) => item.name === 'Exempt');
      // The following might not be the proper implementation.
      if (!firstRecognisedQualification && qualifications?.length > 0) {
        qualificationDeadline = qualificationDeadlineList?.listItems?.find((item) => item.name === '2011-12-31');
      } else {
        qualificationDeadline = qualificationDeadlineList?.listItems?.find((item) => item.name === '2013-12-31');
      }
      reExamDeadline = regulatoryExamDeadlineList?.listItems?.find((item) => item.name === '2013-03-31');
    } else {
      if (isStartDateGreaterThanEndDate(new Date("2019-02-01"), dofaStart)) {
        classOfBusinessDeadline = classOfBusinessList?.listItems?.find((item) => item.name === '2020/12/15');
      } else {
        classOfBusinessDeadline = classOfBusinessList?.listItems?.find((item) => item.name === '12 months from DOFA');
      }
      qualificationDeadline = qualificationDeadlineList?.listItems?.find((item) => item.name === '72 months from DOFA');
      reExamDeadline = regulatoryExamDeadlineList?.listItems?.find((item) => item.name === '24 months from DOFA');
    }

    const record = {
      productSubcategoryId: cob.value,
      productTierId,
      classOfBusinessRequiredId,
      dofaProductSubcategory: cob.label,
      dofaStartDate: dofaStart,
      dofaTerminationDate: dofaEnd ?? new Date(),
      classOfBusinessDeadlineId: classOfBusinessDeadline?.id,
      //classOfBusinessDeadlineInput: classOfBusinessDeadline?.name,
      regulatoryExaminationFiveDeadlineId: reExamDeadline?.id,
      //regulatoryExaminationFiveDeadlineInput: reExamDeadline?.name,
      regulatoryExaminationFiveAchievedDate: re5ExamCompletion?.dateOfExamAchieved,
      qualificationDeadlineId: qualificationDeadline?.id,
      //qualificationDeadlineInput: qualificationDeadline?.name,
      qualificationAchievedDate: firstRecognisedQualification?.dateOfQualificationAchieved,
      totalExperienceGained,
      minimumAdvisoryExperienceRequiredId,
      minimumIntermediaryExperienceRequiredId
    }

    const newCobs = [...cobs, record];
    newCobs.sort((a, b) => {
      if (a.dofaProductSubcategory < b.dofaProductSubcategory) return -1;
      if (a.dofaProductSubcategory > b.dofaProductSubcategory) return 1;
      return 0;
    });

    console.log('COB List: ', newCobs);

    return {
      newCobs,
      resetFields: {
        cob: [{ value: "0", label: "No Product Subcategory" }],
        dofaStart: undefined,
        dofaEnd: undefined
      }
    };
  } catch (error) {
    console.error('Failed to add cob record citing error: ', error);
    ErrorHandling.toastifyError(error.message || "Failed to add record!");
    return null; // Indicates failure
  }
};

export const removeCobRecord = (
  cobs, recordIndex
) => {
  try {
    const newCobs = cobs.filter((_, index) => index !== recordIndex);
    newCobs.sort((a, b) => {
      if (a.dofaProductSubcategory < b.dofaProductSubcategory) return -1;
      if (a.dofaProductSubcategory > b.dofaProductSubcategory) return 1;
      return 0;
    });

    const stateUpdates = {
      newCobs
    };

    return stateUpdates;
  } catch (error) {
    console.error("Failed to remove item from cobs array: ", error);
    return null; // Indicates failure
  }
};
