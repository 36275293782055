import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import TopContainer from '../../../../../components/containers/TopContainer';
import SubNavContainer from '../../../../../components/containers/SubNavContainer';

const Assessments = () => {
  return (
    <>
      <TopContainer />
      <SubNavContainer>
        <ul>
          <li><Link to={`/fsp/dashboard/competency/assessments/new`}><FontAwesomeIcon icon={faPlusSquare} className='awesome-icon'/> New Assessment</Link></li>
          <li><Link to={`/fsp/dashboard/competency/assessments/all`}><FontAwesomeIcon icon={faFolderOpen} className='awesome-icon'/> All Assessments</Link></li>
        </ul>
      </SubNavContainer>
      <div className='operations-section'>
        <Outlet />
      </div>
    </>
  );
};

export default Assessments;