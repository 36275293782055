import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./single-card.scss";

const SingleEntryCard = ({ icon, value = "No value", iconColor = "#7D8973" }) => {
  return (
    <div className="single-entry-card">
      <div className="left-space">
        <FontAwesomeIcon icon={icon} color={iconColor} />
      </div>
      <div className="right-space">
        <div className="value-box">
          <p>{value}</p>
        </div>
      </div>
    </div>
  );
}

export default SingleEntryCard;
