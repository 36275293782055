import React from "react";
import "./fancy-cancel.scss";

const FancyCancel = ({ title = "Cancel", onClick = () => {} }) => {
  return (
    <button className="fancy-cancel-button" onClick={onClick}>
      <span className="circle1"></span>
      <span className="circle2"></span>
      <span className="circle3"></span>
      <span className="circle4"></span>
      <span className="circle5"></span>
      <span className="text">{title}</span>
    </button>
  );
}

export default FancyCancel;
