import axios from "axios";

class ApiService {
  static getApiUrl(endpoint, queryParams = '') {
    const queryString = `ApiKey=${process.env.REACT_APP_MASTER_API_KEY}${queryParams !== '' ? `&${queryParams}` : ''}`;
    return `${process.env.REACT_APP_API_BASE_URL}/api/${endpoint}?${queryString}`;
  }

  static getAuthHeaders() {
    const token = localStorage.getItem('sternatoken');
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  static async get(endpoint, queryParams = '') {
    try {
      return await axios.get(this.getApiUrl(endpoint, queryParams), {
        headers: this.getAuthHeaders()
      });
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}: `, error);
      throw error;
    }
  }

  static async post(endpoint, data) {
    try {
      return await axios.post(this.getApiUrl(endpoint), data, {
        headers: this.getAuthHeaders()
      });
    } catch (error) {
      console.error(`Error posting data to ${endpoint}: `, error);
      throw error;
    }
  }

  static async patch(endpoint, data) {
    try {
      return await axios({
        method: 'PATCH',
        url: this.getApiUrl(endpoint),
        headers: this.getAuthHeaders(),
        data
      });
    } catch (error) {
      console.error(`Error patching record at ${endpoint}: `, error);
      throw error;
    }
  }

  static async put(endpoint, data) {
    try {
      return await axios({
        method: 'PUT',
        url: this.getApiUrl(endpoint),
        headers: this.getAuthHeaders(),
        data
      });
    } catch (error) {
      console.error(`Error putting record at ${endpoint}: `, error);
      throw error;
    }
  }

  static async delete(endpoint, data, queryParams = '') {
    try {
      return await axios({
        method: 'DELETE',
        url: this.getApiUrl(endpoint, queryParams),
        headers: this.getAuthHeaders(),
        data
      });
    } catch (error) {
      console.error(`Error deleting record at ${endpoint}: `, error);
      throw error;
    }
  }
}

export default ApiService;
