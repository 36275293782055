import React from 'react';

const SubNavContainer = ({ nobg = false, children }) => {
  const containerClass = nobg ? "subnavcontainer no-bg" : "subnavcontainer";

  return (
    <div className={containerClass}>
      {children}
    </div>
  );
}

export default SubNavContainer;
