import React from 'react';
import SideImageCard from '../../../../../../../components/cards/SideImageCard';
import StylishInput from '../../../../../../../components/formcomponents/inputs/StylishInput';
import infoImage from '../../../../../../../images/in-app/cardimages/info.png';
import { faPencilAlt, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import '../../brokers-new.scss';
import AppleSwitch from '../../../../../../../components/toggleswitches/AppleSwitch';
import FancySwitch from '../../../../../../../components/toggleswitches/FancySwitch';

const PersonalCard = ({
  brokerName,
  brokerSurname,
  brokerId,
  needsSupervision,
  debarred,
  brokerNameChange = () => { },
  brokerSurnameChange = () => { },
  brokerIdChange = () => { },
  needsSupervisionChange = () => { },
  debarmentChange = () => { }
}) => {
  const yesStyle = {
    color: "#667F33",
    fontWeight: "bold"
  };

  const noStyle = {
    color: "#F66",
    fontWeight: "bold"
  };

  return (
    <SideImageCard img={infoImage} imgAlt="Info">
      <div className="brokers-new-info-form">
        <div className='brokers-new-name-box'>
          <h4>Personal Information</h4>
        </div>
        <div className='brokers-new-name-box'>
          <StylishInput icon={faPencilAlt} type="text" value={brokerName} onChange={brokerNameChange} placeholder="broker name" />
          <StylishInput icon={faPencilAlt} type="text" value={brokerSurname} onChange={brokerSurnameChange} placeholder="broker surname" />
        </div>
        <StylishInput icon={faIdBadge} type="text" value={brokerId} onChange={brokerIdChange} placeholder="broker id" groupStyles={{ maxWidth: 410 }} />
        <div className='brokers-new-input-pair'>
          <div className='brokers-new-input-label' style={{ width: 300 }}>
            <p>Supervision Required: <span style={needsSupervision ? yesStyle : noStyle}>{needsSupervision ? " [NO]" : " [YES]"}</span></p>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
            <FancySwitch checked={needsSupervision} onChange={needsSupervisionChange} colorLeft="#F66" colorRight="#667F33" />
          </div>
        </div>
        <div className='brokers-new-input-pair'>
          <div className='brokers-new-input-label' style={{ width: 300 }}>
            <p>Debarment Test: <span style={debarred ? yesStyle : noStyle}>{debarred ? " [Negative]" : " [Positive]"}</span></p>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
            <FancySwitch checked={debarred} onChange={debarmentChange} colorLeft="#F66" colorRight="#667F33" />
          </div>
        </div>
      </div>
    </SideImageCard>
  );
};

export default PersonalCard;