import Home from '../../screens/public/Home';
import ErrorPage from '../ErrorPage';
import PrivacyPolicy from '../../screens/public/PrivacyPolicy';
import TermsConditions from '../../screens/public/TermsConditions';
import ForgotPassword from '../../screens/public/ForgotPassword';

export const PublicRoutes = [
  {
    path: "/",
    exact: true,
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />
  },
  {
    path: "/tnc",
    element: <TermsConditions />
  }
];