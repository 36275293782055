import React from 'react';
import SideImageCard from '../../../../../../../components/cards/SideImageCard';
import NumberValueCard from '../../../../../../../components/cards/NumberValueCard';
import DurationCard from '../../../../../../../components/cards/DurationCard';
import DualColumnAdjustable from '../../../../../../../components/containers/DualColumnAdjustable';
import workImage from '../../../../../../../images/in-app/cardimages/work.png';
import EmploymentRecordForm from './EmploymentRecordForm';
import EmploymentList from './EmploymentList';

const EmploymentCard = ({
  employments,
  experience,
  dofaIndex,
  setEmployments = () => { },
  setExperience = () => { },
  setGaps = () => { },
  setDofaIndex = () => { },
  setDofaDate = () => { },
}) => {
  return (
    <SideImageCard img={workImage} imgAlt="Work">
      <div className='brokers-new-info-form'>
        <div className='brokers-new-name-box'>
          <h4>Employment Record</h4>
        </div>
        <DualColumnAdjustable
          leftFlex={1}
          leftContent={<>
            <NumberValueCard
              value={employments?.length ?? 0}
              valueColor={employments?.length > 0 ? '#72cc72' : '#e57373'}
              title={employments?.length === 1 ? 'FSP' : 'FSP\'s'}
              titleColor='#7D8973'
            />
          </>}
          rightFlex={2}
          rightContent={<>
            <DurationCard
              title="XP"
              years={experience.years}
              months={experience.months}
              days={experience.days}
            />
          </>}
        />
        <hr />
        <EmploymentRecordForm
          employments={employments}
          setEmployments={setEmployments}
          setDofaIndex={setDofaIndex}
          setDofaDate={setDofaDate}
          setExperience={setExperience}
          setGaps={setGaps}
        />
        <hr />
        <EmploymentList
          employments={employments}
          dofaIndex={dofaIndex}
          setEmployments={setEmployments}
          setExperience={setExperience}
          setGaps={setGaps}
          setDofaIndex={setDofaIndex}
          setDofaDate={setDofaDate}
        />
      </div>
    </SideImageCard>
  );
};

export default EmploymentCard;