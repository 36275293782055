import React, { useState, useEffect } from 'react';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import ApiService from '../../../../../auth/ApiService';
import { useAuth } from '../../../../../auth/AuthContext';
import MainList from '../../../../../components/containers/MainList';
import BrokerItem from '../../../../../components/listitems/sidelistitems/BrokerItem';
import ItemDetailWithTitle from '../../../../../components/containers/ItemDetailWithTitle';
import { sortListItemsByName } from '../../../../../utils/arrays/ArrayFunctions';

const BrokersAll = () => {
  const { sternaAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(undefined);
  const [resetCheckboxes, setResetCheckboxes] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = sternaAuth.getToken();
        const claims = sternaAuth.parseJwt(token);
        const response = await ApiService.get('brokerage', `userId=${claims.UserId}`);
        if (response?.data?.message === 'Success') {
          const brokerList = response?.data?.brokerages.reduce((acc, brokerage) => {
            const brokersWithBrokerage = brokerage.brokers.map(broker => ({
              ...broker,
              brokerage: {
                name: brokerage.name,
                id: brokerage.id,
                fspNumber: brokerage.fspNumber
              }
            }));
            return acc.concat(brokersWithBrokerage);
          }, []);
          setBrokers(brokerList);
        }
      } catch (error) {
        console.log('Error fetching brokerages: ', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <MainList
      loading={loading}
      entities={sortListItemsByName(brokers)}
      noItemTitle="📭 No Brokers"
      renderEntity={(broker, index) => <BrokerItem key={index} broker={broker} />}
    >
      <ItemDetailWithTitle
        title="Nothing yet"
        entityDetail={undefined}
        icon={faUserGraduate}
        onDelete={() => { }}
      >
      </ItemDetailWithTitle>
    </MainList>
  );
}

export default BrokersAll;
