import React from "react";
import SimpleCard from "../SimpleCard";
import './number-value-card.scss';

const NumberValueCard = ({ value, title, valueColor = '#000', titleColor = '#000' }) => {
  return (
    <SimpleCard style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
      <div className="number-value-card-value">
        <p style={{ color: valueColor }}>{value}</p>
      </div>
      <div className="number-value-card-title">
        <p style={{ color: titleColor }}>{title}</p>
      </div>
    </SimpleCard>
  );
}

export default NumberValueCard;
