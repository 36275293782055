import React, { useState, useEffect } from 'react';
import ListItemBase from '../../genericitems/ListItemBase';
import '../../../../scss/main.scss';
import './brokerage-broker-item.scss';
import JellyCheckbox from '../../../formcomponents/checkboxes/JellyCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';

const BrokerageBrokerItem = ({ entry, resetCheckbox, index, prefix = "entry", onClick = () => { } }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [resetCheckbox]);

  const selectItem = (event) => {
    setChecked(event.target.checked);
  }

  return (
    <div className='brokerage-broker-item-wrapper'>
      <ListItemBase
        leftComponent={<JellyCheckbox id={`${prefix}-jelly-checkbox-${index}`} checked={checked} onChange={selectItem} />}
        onClick={() => onClick(entry)}
      >
        <p><FontAwesomeIcon icon={faUserAlt} color='#7D8973' /> {entry?.name ? `${entry.name} ${entry.surname}` : "No name"}</p>
      </ListItemBase>
    </div>
  );
}

export default BrokerageBrokerItem;
