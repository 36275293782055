import React from "react";
import "./simple-card.scss";

const SimpleCard = ({ children, style = {} }) => {
  return (
    <div className="simple-card" style={style}>
      {children}
    </div>
  );
}

export default SimpleCard;