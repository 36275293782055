import React from "react";
import './side-image-card.scss';

const SideImageCard = ({ img, imgAlt, children }) => {
  return (
    <div className="side-image-card-container">
      <div className="side-image-card-image-container">
        <img src={img} alt={imgAlt} />
      </div>
      <div className="side-image-card-content-container">
        {children}
      </div>
    </div>
  );
}

export default SideImageCard;
