import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { useNavigation } from './NavigationContext';
import LoadingOverlay from '../components/widgets/FullPageLoader';

const PrivateRoute = ({ children }) => {
  const { sternaAuth } = useAuth();
  const { sternaNavigator } = useNavigation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthenticated = await sternaAuth.checkAuthentication();
      if (!isAuthenticated && sternaNavigator.getSignInState()) {
        sternaNavigator.toggleSignIn();
        localStorage.removeItem('sternatoken');
      } else if (isAuthenticated && !sternaNavigator.getSignInState()) {
        sternaNavigator.toggleSignIn();
      }
      setLoading(false);
    }
    checkAuth();
  }, []);

  if (loading) {
    return (
      <div className='full-height-section'>
        <LoadingOverlay loading />
      </div>
    );
  }

  if (!sternaNavigator.getSignInState()) {
    console.log("Cannot access Private Route without being authenticated!");
    return <Navigate to="/" replace />
  }
  return children;
};

export default PrivateRoute;