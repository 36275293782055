import React from "react";
import SimpleCard from "../SimpleCard";
import "./duration-card.scss";

const DurationCard = ({ title, years, months, days, titleColor }) => {
  return (
    <SimpleCard>
      <div className="duration-card-title">
        <p style={titleColor ? { color: titleColor } : {}}>{title}</p>
      </div>
      <div className="duration-card-value-container">
        <div className="duration-card-value duration-card-value-border">
          <p>YEARS</p>
          <h4>{years}</h4>
        </div>
        <div className="duration-card-value duration-card-value-border">
          <p>MONTHS</p>
          <h4>{months}</h4>
        </div>
        <div className="duration-card-value">
          <p>DAYS</p>
          <h4>{days}</h4>
        </div>
      </div>
    </SimpleCard>
  );
}

export default DurationCard;
