import React from "react";
import "./top-bar.scss"

const TopBar = ({ children }) => {
  return (
    <div className="top-bar">
      {children}
    </div>
  );
}

export default TopBar;
