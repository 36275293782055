import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import ListItemBase from "../../genericitems/ListItemBase";

const BrokerageItem = ({ brokerage, onClick = () => {} }) => {
  const brokeragename = brokerage?.name ?? 'Not named';

  return (
    <ListItemBase
      leftComponent={<FontAwesomeIcon icon={faBuilding} color="#7D8973" />}
      onClick={() => onClick(brokerage)}
    >
      <p>{brokeragename}</p>
    </ListItemBase>
  );
}

export default BrokerageItem;
