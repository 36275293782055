import React, { useState, useEffect } from 'react';
import ApiService from '../../../../../auth/ApiService';
import ErrorHandling from '../../../../../utils/errors/ErrorHandling';
import { useAuth } from '../../../../../auth/AuthContext';
import MainList from '../../../../../components/containers/MainList';
import AssessmentItem from '../../../../../components/listitems/sidelistitems/AssessmentItem';
import ItemDetailWithTitle from '../../../../../components/containers/ItemDetailWithTitle';
import FancySubmit from '../../../../../components/buttons/FancySubmit';
import { faScroll } from '@fortawesome/free-solid-svg-icons';

const AssessmentsAll = () => {
  const { sternaAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [assessments, setAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = sternaAuth.getToken();
        const claims = sternaAuth.parseJwt(token);
        const response = await ApiService.get('competencyassessment', `userId=${claims.UserId}`);
        if (response?.data?.message === 'Success') {
          setAssessments(response.data.competencyAssessments);
        }
      } catch (error) {
        console.log('Error fetching assessments: ', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleAssessmentSelect = (assessment) => {
    setSelectedAssessment(assessment);
  }

  const handleAssessmentDelete = () => {
    console.log("Deleting Assessment with id ", selectedAssessment?.id);
  }

  const handleGenerateReport = async () => {
    try {
      if (selectedAssessment) {
        const response = await ApiService.post('complianceToolboxReports/competency-assessment-report', {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          competencyAssessmentId: selectedAssessment.id,
          reportTemplateId: "85C298A5-4FBE-4FE4-A8E2-3BDF38CD5EE2"
        });

        if (response?.data?.reportResult?.link) {
          ErrorHandling.toastifySuccess("Report has been generated!");
          downloadPdf(response.data.reportResult.link);
          return ErrorHandling.toastifySuccess("Report has been downloaded!");
        }

        if (response?.data?.message) {
          return ErrorHandling.toastifyError(response.data.message);
        }

        return ErrorHandling.toastifyError("Report failed to generate!");
      }
    } catch (error) {
      // Log error here...
      return ErrorHandling.toastifyError("Exception was thrown while generating report!");
    }
  }

  const downloadPdf = (pdfLink) => {
    // Create a new anchor element
    const link = document.createElement("a");
    link.href = pdfLink;
    link.download = "report.pdf";

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically trigger a click event
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <MainList
      loading={loading}
      entities={assessments}
      noItemTitle="📭 No Assessments"
      renderEntity={(assessment, index) => <AssessmentItem key={index} assessment={assessment} onClick={handleAssessmentSelect} />}
    >
      <ItemDetailWithTitle
        title={selectedAssessment?.name}
        entityDetail={selectedAssessment}
        icon={faScroll}
        onDelete={handleAssessmentDelete}
      >
        <div className='content'>
          <FancySubmit title='Generate Report' onClick={handleGenerateReport} />
        </div>
      </ItemDetailWithTitle>
    </MainList>
  );
}

export default AssessmentsAll;
