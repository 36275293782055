import React, { useEffect, useRef } from "react";
import "./right-component.scss";

const RightComponent = ({ children }) => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollLeft = scrollWidth - clientWidth;
    }
  }, []);

  return (
    <div className="right-component" ref={scrollContainerRef}>
      {children}
    </div>
  );
};

export default RightComponent;
