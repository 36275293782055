import React, { useState, useEffect } from 'react';
import { faBuildingUser, faHashtag, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import ApiService from '../../../../../auth/ApiService';
import ErrorHandling from '../../../../../utils/errors/ErrorHandling';
import { useAuth } from '../../../../../auth/AuthContext';
import MainList from '../../../../../components/containers/MainList';
import BrokerageItem from '../../../../../components/listitems/sidelistitems/BrokerageItem';
import ItemDetailWithTitle from '../../../../../components/containers/ItemDetailWithTitle';
import BrokerageBrokerItem from '../../../../../components/listitems/detailitems/BrokerageBrokerItem';
import { sortListItemsByName } from '../../../../../utils/arrays/ArrayFunctions';
import SingleCard from '../../../../../components/cards/SingleCard';
import SingleEntryCard from '../../../../../components/cards/SingleEntryCard';
import Popup from '../../../../../components/widgets/Popup';
import FancyConfirm from '../../../../../components/buttons/FancyConfirm';
import FancyCancel from '../../../../../components/buttons/FancyCancel';
import "./brokerages-all.scss";

const BrokeragesAll = () => {
  const { sternaAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [brokerages, setBrokerages] = useState([]);
  const [selectedBrokerage, setSelectedBrokerage] = useState(undefined);
  const [resetCheckboxes, setResetCheckboxes] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = sternaAuth.getToken();
        const claims = sternaAuth.parseJwt(token);
        const response = await ApiService.get('brokerage', `userId=${claims.UserId}`);
        if (response?.data?.message === 'Success') {
          console.log(response.data.brokerages);
          setBrokerages(response.data.brokerages);
        }
      } catch (error) {
        console.log('Error fetching brokerages: ', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const onBrokerageSelect = (brokerage) => {
    setSelectedBrokerage(brokerage);
    setResetCheckboxes(new Date().getTime());
  }

  const onBrokerageDelete = () => {
    try {
      setDeleting(true);
    } catch (error) {
      console.error("An error occurred while deleting a brokerage: ", error);
      setDeleting(false);
      return ErrorHandling.toastifyError("Failed to delete Brokerage!");
    }
  }

  const handleDelete = async () => {
    try {
      if (!selectedBrokerage?.id) {
        return ErrorHandling.toastifyError("Brokerage Id not found!");
      }
      if (selectedBrokerage?.brokers?.legnth > 0) {
        return ErrorHandling.toastifyError("Brokerage still has Brokers!");
      }

      const token = sternaAuth.getToken();
      const claims = sternaAuth.parseJwt(token);
      const response = await ApiService.delete(`brokerage/${selectedBrokerage.id}`, `userId=${claims.UserId}`);
      if (response?.data?.message === 'Delete Success') {
        setBrokerages(brokerages.filter((brokerage) => brokerage.id !== selectedBrokerage.id));
        setSelectedBrokerage(undefined);
        return ErrorHandling.toastifySuccess("Brokerage Deleted!");
      }
      console.log("Deleting Brokerage... ", response?.data?.message);
    } catch (error) {
      console.error("An error occurred while deleting a brokerage: ", error);
      return ErrorHandling.toastifyError("Failed to delete Brokerage!");
    } finally {
      setDeleting(false);
    }
  }

  return (
    <MainList
      loading={loading}
      entities={sortListItemsByName(brokerages)}
      noItemTitle="📭 No Brokerages"
      renderEntity={(brokerage, index) => <BrokerageItem key={index} brokerage={brokerage} onClick={onBrokerageSelect} />}
    >
      <ItemDetailWithTitle
        title={selectedBrokerage?.name}
        entityDetail={selectedBrokerage}
        icon={faBuildingUser}
        onDelete={onBrokerageDelete}
      >
        <div className='content'>
          <SingleEntryCard icon={faHashtag} title="FSP Number" value={selectedBrokerage?.fspNumber} />
          {
            selectedBrokerage?.brokers?.length > 0 ?
              sortListItemsByName(selectedBrokerage.brokers).map((broker, index) =>
                <BrokerageBrokerItem key={index} resetCheckbox={resetCheckboxes} prefix={selectedBrokerage.name.replace(/\s+/g, '')} index={index} entry={broker} />) :
              <SingleCard icon={faUserAlt} value="No Brokers added!" iconColor='#e57373' />
          }
        </div>
      </ItemDetailWithTitle>
      <Popup isLoading={deleting}>
        <p>Are you sure you wish to delete this Brokerage?</p>
        <div className='brokerage-popup-actions'>
          <FancyConfirm title="Yes" onClick={handleDelete} />
          <FancyCancel title="No" onClick={() => setDeleting(false)} />
        </div>
      </Popup>
    </MainList>
  );
}

export default BrokeragesAll;
