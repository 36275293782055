import { DateTime } from "luxon";

/**
 * Checks if the start date is greater than the end date.
 * If the end date is not provided, it defaults to the current date/time.
 *
 * @param {DateTime | Date} startDate - The start date.
 * @param {DateTime | Date} [endDate=DateTime.now()] - The end date, defaults to the current date/time if not provided.
 * @returns {boolean} Returns true if the start date is after the end date, false otherwise.
 */
export const isStartDateGreaterThanEndDate = (startDate, endDate = DateTime.now()) => {
  const start = startDate instanceof DateTime ? startDate : DateTime.fromJSDate(startDate);
  const end = endDate instanceof DateTime ? endDate : DateTime.fromJSDate(endDate);

  return start > end;
};

/**
 * Calculates the time difference between two dates.
 * @param {Date} startDate - The start date.
 * @param {Date} [endDate=DateTime.now()] - The end date. Defaults to current date/time if not provided.
 * @returns {Object} An object containing the difference in years, months, and days.
 */
export const calculateTimeDifference = (startDate, endDate = new Date()) => {
  try {
    const dateStart = DateTime.fromJSDate(startDate);
    const dateEnd = DateTime.fromJSDate(endDate);
    const diff = dateEnd.diff(dateStart, ['years', 'months', 'days']).toObject();
    console.log('Difference: ', diff);
    return {
      years: Math.floor(diff.years),
      months: Math.floor(diff.months),
      days: Math.floor(diff.days)
    };
  } catch (error) {
    console.log("Error calculating time difference: ", error);
    return { years: 0, months: 0, days: 0 };
  }
}

/**
 * Calculates the difference in months between two dates.
 * @param {Date} startDate - The start date.
 * @param {Date} [endDate=DateTime.now()] - The end date. Defaults to current date/time if not provided.
 * @returns {Number} A number greater or equal to zero indicating the difference months.
 */
export const calculateMonthDifference = (startDate, endDate = new Date()) => {
  try {
    const dateStart = DateTime.fromJSDate(startDate);
    const dateEnd = DateTime.fromJSDate(endDate);
    const totalMonths = dateEnd.diff(dateStart, 'months').months;
    return Math.floor(totalMonths);
  } catch (error) {
    console.log("Error calculating difference in months: ", error);
    return 0;
  }
}

const normalizeDuration = (duration) => {
  if (duration.days >= 30) {
    duration.months += Math.floor(duration.days / 30);
    duration.days %= 30;
  }
  if (duration.months >= 12) {
    duration.years += Math.floor(duration.months / 12);
    duration.months %= 12;
  }
};

/**
 * Calculates the total work experience based on an array of employments.
 * Each employment should have a 'dateOfAppointment' and optionally a 'dateOfTermination'.
 * 
 * @param {Array} employments - Array of employment objects. Each object should have 'dateOfAppointment'
 *                              and optionally 'dateOfTermination'. Dates should be in a format parseable by Date constructor.
 * 
 * @returns {Object} An object representing the total duration of work experience in years, months, and days.
 *                   Example: { years: 5, months: 6, days: 15 }
 * 
 * Note: The function assumes that 'dateOfTermination' will be null or undefined for ongoing employments.
 *       Overlapping periods between employments are adjusted to avoid double counting.
 */
export const calculateTotalWorkExperience = (employments) => {
  // Sort employments by date of appointment
  employments.sort((a, b) => new Date(a.dateOfAppointment) - new Date(b.dateOfAppointment));

  let totalDuration = { years: 0, months: 0, days: 0 };
  let lastDateOfTermination = null;

  employments.forEach((employment, index) => {
    let startDate = DateTime.fromJSDate(new Date(employment.dateOfAppointment));
    let endDate = employment.dateOfTermination ? DateTime.fromJSDate(new Date(employment.dateOfTermination)) : DateTime.now();

    // Adjust for overlap
    if (lastDateOfTermination && startDate < lastDateOfTermination) {
      startDate = lastDateOfTermination;
    }

    if (startDate < endDate) {
      const duration = calculateTimeDifference(startDate.toJSDate(), endDate.toJSDate());
      totalDuration.years += duration.years;
      totalDuration.months += duration.months;
      totalDuration.days += duration.days;

      // Normalize the duration (e.g., 30 days => 1 month)
      normalizeDuration(totalDuration);
    }

    lastDateOfTermination = endDate;
  });

  return totalDuration;
};

/**
 * Finds significant gaps in employment history, considering overlapping employment periods and including a gap
 * after the last employment if it exceeds 7 days, thereby disregarding short gaps like weekends.
 * 
 * This function sorts the provided employment records by their date of appointment and iterates
 * through them to identify periods without active employment, factoring in overlaps and a potential gap
 * from the end of the last employment to today's date, if such a gap is significant (exceeding 7 days).
 * 
 * @param {Object[]} employments - Array of employment objects, each with a `brokerageId`,
 * `brokerageName`, `dateOfAppointment`, and `dateOfTermination` (the latter may be `undefined`
 * for ongoing employments). It's assumed `dateOfAppointment` and `dateOfTermination` are
 * JavaScript Date objects.
 * 
 * @returns {Object[]} An array of gap objects, each with `gapStart` and `gapEnd` properties indicating
 * the start and end of each significant employment gap. Short gaps of 7 days or less are disregarded.
 * Returns an empty array if no gaps are found.
 * 
 * Example usage:
 * ```javascript
 * const employments = [
 *   { brokerageId: "123", brokerageName: "Brokerage 1", dateOfAppointment: new Date('2020-01-01'), dateOfTermination: new Date('2021-12-31') },
 *   { brokerageId: "456", brokerageName: "Brokerage 2", dateOfAppointment: new Date('2020-01-01'), dateOfTermination: new Date('2021-10-31') },
 *   { brokerageId: "789", brokerageName: "Brokerage 3", dateOfAppointment: new Date('2022-03-01'), dateOfTermination: new Date('2022-12-31') }
 * ];
 * const gaps = findEmploymentGapsWithOverlaps(employments);
 * console.log(gaps);
 * ```
 * 
 * Note:
 * - Assumes input employments array is free from data inconsistencies, such as employments
 *   with a `dateOfAppointment` after the `dateOfTermination`. Validate or clean data prior
 *   to usage if inconsistencies may exist.
 */
export const findEmploymentGaps = (employments) => {
  const sortedEmployments = employments.sort((a, b) => a.dateOfAppointment - b.dateOfAppointment);
  const gaps = [];
  let latestTerminationDate = null;

  for (const employment of sortedEmployments) {
    const terminationDate = employment.dateOfTermination ? employment.dateOfTermination.getTime() : new Date().getTime();

    if (latestTerminationDate && latestTerminationDate < employment.dateOfAppointment.getTime()) {
      const gapDuration = (employment.dateOfAppointment - new Date(latestTerminationDate)) / (1000 * 60 * 60 * 24);
      if (gapDuration > 7) {
        gaps.push({
          gapStart: new Date(latestTerminationDate),
          gapEnd: new Date(employment.dateOfAppointment)
        });
      }
    }

    latestTerminationDate = latestTerminationDate ? Math.max(latestTerminationDate, terminationDate) : terminationDate;
  }

  const today = new Date().getTime();
  if (latestTerminationDate && latestTerminationDate < today) {
    const gapDuration = (today - latestTerminationDate) / (1000 * 60 * 60 * 24);
    if (gapDuration > 7) {
      gaps.push({
        gapStart: new Date(latestTerminationDate),
        gapEnd: new Date()
      });
    }
  }

  return gaps;
};
