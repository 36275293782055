import React from "react";
import "./broker-qualification-item.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faInstitution, faCalendarCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const BrokerQualificationItem = ({ qualification, index, lastItem = false, onDelete = (i) => console.log(i) }) => {
  var achievedDate = qualification?.dateOfQualificationAchieved ? new Date(qualification?.dateOfQualificationAchieved).toISOString().split('T')[0] : "";
  var containerStyle = lastItem ?
    "broker-employment-item-container broker-employment-item-container-bottom-margin" :
    "broker-employment-item-container";

  return (
    <div className={containerStyle}>
      <div className="broker-employment-item-icon-box">
        <FontAwesomeIcon icon={faGraduationCap} className="broker-employment-item-icon-style" />
      </div>
      <div className="broker-employment-item-detail-box">
        <h4>{qualification?.qualificationName ?? "Unknown Qualification"}</h4>
        <div className="broker-employment-item-history-box">
          <div className="broker-employment-item-date-box">
            <FontAwesomeIcon icon={faCalendarCheck} className="broker-employment-item-date-icon-style" />
            <p>{achievedDate}</p>
          </div>
          <div className="broker-employment-item-date-box broker-employment-item-date-box-offset">
            <FontAwesomeIcon icon={faInstitution} className="broker-employment-item-date-icon-style" />
            <p>{qualification?.institution ?? "Unknown Institution"}</p>
          </div>
        </div>
      </div>
      <div className="broker-employment-item-close-box" onClick={() => onDelete(index)}>
        <FontAwesomeIcon icon={faTrashAlt} className="broker-employment-item-close-icon-style" />
      </div>
    </div>
  );
}

export default BrokerQualificationItem;
