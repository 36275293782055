import React from "react";
import './dual-column-adjustable.scss';

const FlexContainer = ({ leftFlex, rightFlex, leftContent, rightContent }) => {
  return (
    <div className="dual-column-adjustable-container">
      <div className="dual-column-adjustable-child-container" style={{ flex: leftFlex }}>
        {leftContent}
      </div>
      <div className="dual-column-adjustable-child-container" style={{ flex: rightFlex }}>
        {rightContent}
      </div>
    </div>
  );
}

export default FlexContainer;
