import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../../../auth/AuthContext';
import { DateTime } from 'luxon';
import StylishSelect from '../../../../../../../components/formcomponents/inputs/StylishSelect';
import StylishDateTimePicker from '../../../../../../../components/formcomponents/inputs/StylishDateTimePicker';
import FancySubmit from '../../../../../../../components/buttons/FancySubmit';
import fetchData from '../../brokerService';
import { addEmploymentRecord } from '../../handlers/employmentHandlers';
import { faBuildingUser, faCalendarCheck, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import '../../brokers-new.scss';

const EmploymentRecordForm = ({
  employments,
  setEmployments = () => { },
  setDofaIndex = () => { },
  setDofaDate = () => { },
  setExperience = () => { },
  setGaps = () => { }
}) => {
  const { sternaAuth } = useAuth();

  const [brokerage, setBrokerage] = useState();
  const [options, setOptions] = useState([{ value: "0", label: "No Brokerage" }]);
  const [appointmentDate, setAppointmentDate] = useState(DateTime.now().toJSDate());
  const [terminationDate, setTerminationDate] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData(
      sternaAuth,
      (availableOptions) => setOptions(availableOptions),
      () => setLoading(true),
      () => setLoading(false)
    );
  }, [sternaAuth]);

  const handleBrokerageSelect = (selection) => {
    setBrokerage(selection);
  }

  const handleAppointmentDateChange = (newDate) => {
    setAppointmentDate(newDate);
  }

  const handleTerminationDateChange = (newDate) => {
    setTerminationDate(newDate);
  }

  const handleEmploymentRecordAdd = () => {
    const result = addEmploymentRecord(brokerage, appointmentDate, terminationDate, employments);
    if (result) {
      setEmployments(result.newEmployments);
      setDofaIndex(result.dofaIndex);
      setDofaDate(result.dofaDate);
      setExperience(result.experience);
      setGaps(result.gaps);
      // Reset fields
      setBrokerage(result.resetFields.brokerage);
      setAppointmentDate(result.resetFields.appointmentDate);
      setTerminationDate(result.resetFields.terminationDate);
    }
  }

  return (
    <>
      <div className='brokers-new-name-box'>
        <h4>Add Employment:</h4>
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label' style={{ width: 100 }}>
          <p>Select FSP:</p>
        </div>
        <StylishSelect
          icon={faBuildingUser}
          options={options}
          isLoading={loading}
          isClearable
          isSearchable
          name="broker-brokerage-select"
          onChange={handleBrokerageSelect}
          value={brokerage}
          controlStyles={{ width: 300 }}
          menuStyles={{ zIndex: 100 }}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Appointment Date:</p>
        </div>
        <StylishDateTimePicker
          date={appointmentDate}
          onSelect={() => { }}
          onChange={handleAppointmentDateChange}
          icon={faCalendarCheck}
          groupStyles={{ width: 200 }}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Termination Date:</p>
        </div>
        <StylishDateTimePicker
          date={terminationDate}
          onSelect={() => { }}
          onChange={handleTerminationDateChange}
          icon={faCalendarTimes}
          groupStyles={{ width: 200 }}
        />
      </div>
      <div className='brokers-new-name-box align-right'>
        <FancySubmit title='Add Record' onClick={handleEmploymentRecordAdd} />
      </div>
    </>
  );
}

export default EmploymentRecordForm;
