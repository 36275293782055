import { DateTime } from 'luxon';
import ErrorHandling from '../../../../../../utils/errors/ErrorHandling';
import {
  isStartDateGreaterThanEndDate,
  calculateTotalWorkExperience,
  findEmploymentGaps
} from '../../../../../../utils/datetime/dateTimeUtils';

export const addEmploymentRecord = (
  brokerage, appointmentDate, terminationDate, employments
) => {
  try {
    if (!brokerage?.value) {
      return ErrorHandling.toastifyError("Select a Brokerage!");
    }
    if (!appointmentDate) {
      return ErrorHandling.toastifyError("Set an appointment date!");
    }
    if (isStartDateGreaterThanEndDate(appointmentDate)) {
      return ErrorHandling.toastifyError("Appointment date cannot be in the future!");
    }
    if (terminationDate && isStartDateGreaterThanEndDate(terminationDate)) {
      return ErrorHandling.toastifyError("Termination date cannot be in the future!");
    }

    const record = {
      brokerageId: brokerage.value,
      brokerageName: brokerage.label,
      dateOfAppointment: appointmentDate,
      dateOfTermination: terminationDate
    }

    const newEmployments = [...employments, record];
    newEmployments.sort((a, b) => new Date(a.dateOfAppointment) - new Date(b.dateOfAppointment));

    return {
      newEmployments,
      dofaIndex: 0,
      dofaDate: newEmployments[0].dateOfAppointment,
      experience: calculateTotalWorkExperience(newEmployments),
      gaps: findEmploymentGaps(newEmployments),
      resetFields: {
        brokerage: [{ value: "0", label: "No Brokerage" }],
        appointmentDate: DateTime.now().toJSDate(),
        terminationDate: undefined
      }
    };
  } catch (error) {
    console.error('Failed to add employment record citing error: ', error);
    ErrorHandling.toastifyError(error.message || "Failed to add record!");
    return null; // Indicates failure
  }
};

export const removeEmploymentRecord = (
  employments, recordIndex
) => {
  try {
    const newEmployments = employments.filter((_, index) => index !== recordIndex);
    newEmployments.sort((a, b) => new Date(a.dateOfAppointment) - new Date(b.dateOfAppointment));

    const stateUpdates = {
      newEmployments,
      dofaIndex: undefined,
      dofaDate: DateTime.now().toJSDate(),
      experience: { years: 0, months: 0, days: 0 },
      gaps: []
    };

    if (newEmployments.length > 0) {
      stateUpdates.dofaIndex = 0;
      stateUpdates.dofaDate = newEmployments[0].dateOfAppointment;
      stateUpdates.experience = calculateTotalWorkExperience(newEmployments);
      stateUpdates.gaps = findEmploymentGaps(newEmployments);
    }

    return stateUpdates;
  } catch (error) {
    console.error("Failed to remove item from employments array: ", error);
    return null; // Indicates failure
  }
};
