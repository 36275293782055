import React, { useState, useEffect } from 'react';
import ErrorHandling from '../../../../../utils/errors/ErrorHandling';
import { DateTime } from 'luxon';
import { useAuth } from '../../../../../auth/AuthContext';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import MainContent from '../../../../../components/containers/MainContent';
import ItemDetailWithTitle from '../../../../../components/containers/ItemDetailWithTitle';
import FancySubmit from '../../../../../components/buttons/FancySubmit';
import FullPageLoader from '../../../../../components/widgets/FullPageLoader';
import "./brokers-new.scss";
import PersonalCard from './components/personal/PersonalCard';

import { genericFetch } from './brokerService';
import { handleFormSubmit } from './handlers/brokerCreationHandler';
import EmploymentCard from './components/employment/EmploymentCard';
import QualificationsCard from './components/qualifications/QualificationsCard';
import ExaminationsCard from './components/examinations/ExaminationsCard';
import BrokerTimeline from '../../../../../components/widgets/BrokerTimeline';
import CobCard from './components/cob/CobCard';

const BrokersNew = () => {
  const { sternaAuth } = useAuth();
  // Personal Information
  const [brokerName, setBrokerName] = useState("");
  const [brokerSurname, setBrokerSurname] = useState("");
  const [brokerId, setBrokerId] = useState("");
  const [needsSupervision, setNeedsSupervision] = useState(false);
  const [debarred, setDebarred] = useState(false);
  const [debarredOptions, setDebarredOptions] = useState([]);
  // Employment History
  const [employments, setEmployments] = useState([]);
  const [dofaPeriod, setDofaPeriod] = useState();
  const [dofaIndex, setDofaIndex] = useState();
  const [dofaDate, setDofaDate] = useState(DateTime.now().toJSDate());
  const [experience, setExperience] = useState({ years: 0, months: 0, days: 0 });
  const [gaps, setGaps] = useState([]);
  // Education History
  const [qualifications, setQualifications] = useState([]);
  // RE Exams
  const [exams, setExams] = useState([]);
  // Close of Business
  const [cobs, setCobs] = useState([]);
  // Other
  const [submitting, setSubmitting] = useState(false);

  const dofaOptions = [
    { value: "1", label: "1 Oct '04 to 31 Dec '07" },
    { value: "2", label: "1 Jan '08 to 31 Dec '09" },
    { value: "3", label: "1 Jan '10 onwards" }
  ];

  useEffect(() => {
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);
    
    // Fetch list items for Debarment Test:
    genericFetch(
      "list",
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === 'Success',
      (rsp) => {
        const list = rsp?.data?.lists?.find((lst) => lst.name === 'Debarment Test');
        return list?.listItems ?? [];
      },
      (data) => setDebarredOptions(data),
      () => { },
      () => { },
      (err) => console.log('Failed to fetch list items for Debarment Test: ', err)
    );
  }, []);

  useEffect(() => {
    console.log("Qualifications: ", qualifications);
  }, [qualifications]);

  const handleBrokerNameChange = (event) => {
    setBrokerName(event.target.value);
  }

  const handleBrokerSurnameChange = (event) => {
    setBrokerSurname(event.target.value);
  }

  const handleBrokerIdChange = (event) => {
    setBrokerId(event.target.value);
  }

  const handleSupervisionChange = (event) => {
    setNeedsSupervision(event.target.checked);
  }

  const handleDebarmentChange = (event) => {
    setDebarred(event.target.checked);
  }

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      if (!employments || employments.length === 0) {
        return ErrorHandling.toastifyError('You must add at least one valid employment record!');
      }
      const brokerRecord = {
        broker: {
          name: brokerName,
          surname: brokerSurname,
          idNo: brokerId,
          firstIndustryDofaPeriod: "", // For now we set this to an empty string!
          specificFirstIndustryDofaDate: dofaDate,
          superVisionRequired: needsSupervision,
          debarmentTest: debarred ? debarredOptions.find((dt) => dt.name === 'Positive') : debarredOptions.find((dt) => dt.name === 'Negative'),
          // dofaTermination: Date (we are not providing this yet)
          brokerageId: employments[employments.length - 1].brokerageId,
          educationHistory: qualifications.map(qualification => {
            return {
              name: qualification.qualificationName,
              dateAchieved: qualification.dateOfQualificationAchieved,
              institution: qualification.institution,
              recognisedByFSCA: qualification.fscaRecognised
            }
          }),
          workHistory: employments.map(employment => {
            return {
              name: employment.brokerageName.split("|")[0].trim(),
              fSPNumber: employment.brokerageName.split("|")[1].trim(), // Blank for now
              appointedDate: employment.dateOfAppointment,
              terminatedDate: employment.dateOfTermination
            }
          }),
          regulatoryExamRecord: exams.map(exam => {
            return {
              level: exam.levelName,
              dateAchieved: exam.dateOfExamAchieved
            }
          }),
          complianceRequirements: cobs
        }
      };
  
      const result = await handleFormSubmit(brokerRecord, sternaAuth);
  
      if (result.success) {
        ErrorHandling.toastifySuccess(result.message);
        // setBrokerName(result.resetFields.brokerName);
        // setBrokerSurname(result.resetFields.brokerSurname);
        // setBrokerId(result.resetFields.brokerId);
        // setBrokerage(result.resetFields.brokerage);
      } else {
        ErrorHandling.toastifyError(result.message);
      }
    } catch (error) {
      console.log('An error prevented a new Broker to be added: ', error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <MainContent>
      <ItemDetailWithTitle
        title="Add New Broker"
        entityDetail={{ placeholder: "none" }}
        icon={faUserAlt}
        isDelete={false}
      >
        <div className='broker-form'>
          <FancySubmit title="Add Broker" onClick={handleSubmit} />
          <Tabs>
            <TabList>
              <Tab>Personal Information</Tab>
              <Tab>Employment Record</Tab>
              <Tab>Qualifications Record</Tab>
              <Tab>Regulatory Exam Record</Tab>
              <Tab>COB History</Tab>
              <Tab>Timeline</Tab>
            </TabList>
            <TabPanel>
              <PersonalCard
                brokerName={brokerName}
                brokerSurname={brokerSurname}
                brokerId={brokerId}
                needsSupervision={needsSupervision}
                debarred={debarred}
                brokerNameChange={handleBrokerNameChange}
                brokerSurnameChange={handleBrokerSurnameChange}
                brokerIdChange={handleBrokerIdChange}
                needsSupervisionChange={handleSupervisionChange}
                debarmentChange={handleDebarmentChange}
              />
            </TabPanel>
            <TabPanel>
              <EmploymentCard
                employments={employments}
                experience={experience}
                dofaIndex={dofaIndex}
                setEmployments={(newEmployments) => setEmployments(newEmployments)}
                setExperience={(newExperience) => setExperience(newExperience)}
                setGaps={(newGaps) => setGaps(newGaps)}
                setDofaIndex={(newDofaIndex) => setDofaIndex(newDofaIndex)}
                setDofaDate={(newDofaDate) => setDofaDate(newDofaDate)}
              />
            </TabPanel>
            <TabPanel>
              <QualificationsCard
                qualifications={qualifications}
                setQualifications={setQualifications}
              />
            </TabPanel>
            <TabPanel>
              <ExaminationsCard
                examinations={exams}
                setExaminations={setExams}
              />
            </TabPanel>
            <TabPanel>
              <CobCard
                cobs={cobs}
                exams={exams}
                qualifications={qualifications}
                dofaDate={dofaDate}
                setCobs={setCobs}
              />
            </TabPanel>
            <TabPanel>
              <BrokerTimeline employments={employments} gaps={gaps} />
            </TabPanel>
          </Tabs>
        </div>
      </ItemDetailWithTitle>
      <FullPageLoader isLoading={submitting} />
    </MainContent>
  );
}

export default BrokersNew;
