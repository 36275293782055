import { DateTime } from 'luxon';
import ErrorHandling from '../../../../../../utils/errors/ErrorHandling';
import {
  isStartDateGreaterThanEndDate
} from '../../../../../../utils/datetime/dateTimeUtils';

export const addExamRecord = (
  level, achievedDate, examinations
) => {
  try {
    if (!level?.value) {
      return ErrorHandling.toastifyError("Select an RE Level!");
    }
    if (!achievedDate) {
      return ErrorHandling.toastifyError("Set exam achievement date!");
    }
    if (isStartDateGreaterThanEndDate(achievedDate)) {
      return ErrorHandling.toastifyError("Achieved date cannot be in the future!");
    }

    const record = {
      levelId: level.value,
      levelName: level.label,
      dateOfExamAchieved: achievedDate
    }

    const newExaminations = [...examinations, record];
    newExaminations.sort((a, b) => new Date(a.dateOfExamAchieved) - new Date(b.dateOfExamAchieved));

    return {
      newExaminations,
      resetFields: {
        level: [{ value: "0", label: "No Level" }],
        achievedDate: DateTime.now().toJSDate()
      }
    };
  } catch (error) {
    console.error('Failed to add examination record citing error: ', error);
    ErrorHandling.toastifyError(error.message || "Failed to add record!");
    return null; // Indicates failure
  }
};

export const removeExamRecord = (
  examinations, recordIndex
) => {
  try {
    const newExaminations = examinations.filter((_, index) => index !== recordIndex);
    newExaminations.sort((a, b) => new Date(a.dateOfExamAchieved) - new Date(b.dateOfExamAchieved));

    const stateUpdates = {
      newExaminations
    };

    return stateUpdates;
  } catch (error) {
    console.error("Failed to remove item from examinations array: ", error);
    return null; // Indicates failure
  }
};
