import React, { useState, useEffect, useRef } from "react";
import "./broker-timeline.scss";
import LeftComponent from "./components/LeftComponent";
import RightComponent from "./components/RightComponent";
import TopBar from "./components/TopBar";
import { FixedSizeList as List } from "react-window";
import CalendarItem from "./components/CalendarItem";
import FspItem from "./components/FspItem";

const BrokerTimeline = ({ employments, gaps, qualifications, examinations }) => {
  const rightColumnRef = useRef(null);
  const listRef = React.createRef();
  const [monthColumns, setMonthColumns] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setMonthColumns(generateMonthColumns());

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (rightColumnRef.current) {
      resizeObserver.observe(rightColumnRef.current);
    }

    console.log("Gaps: ", gaps);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (listRef?.current && monthColumns?.length > 0) {
      listRef.current.scrollToItem(monthColumns.length, "end");
    }
  }, [monthColumns]);

  const generateMonthColumns = () => {
    const startYear = 2000;
    const endYear = new Date().getFullYear();
    const endMonth = new Date().getMonth();
    const monthAbbreviations = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let monthColumns = [];

    for (let year = startYear; year <= endYear; year++) {
      let lastMonth = year === endYear ? endMonth : 11;
      for (let month = 0; month <= lastMonth; month++) {
        monthColumns.push({
          month: monthAbbreviations[month],
          year: year.toString(),
        });
      }
    }

    return monthColumns;
  }

  const Row = ({ index, style }) => (
    <div style={style}>
      <CalendarItem
        month={monthColumns[index].month}
        year={monthColumns[index].year}
        employments={employments}
        gaps={gaps}
        qualifications={qualifications}
        examinations={examinations}
      />
    </div>
  );

  return (
    <div className="broker-timeline">
      <div className="left-column">
        <LeftComponent>
          <TopBar>
            <div className="broker-timeline-title">
              <p>FSPs</p>
            </div>
          </TopBar>
          {employments?.length > 0 ? employments.map((employment, index) => (
            <FspItem
              key={index}
              fspName={employment.brokerageName ?? "Unknown"}
              fspColor="#f66"
              fspArray={employments}
              index={index}
            />
          )) : null}
          <FspItem fspName="Gaps" fspColor="#ED9E9E" fspArray={["dummy"]} index={0} />
          <FspItem fspName="Qualifications" fspColor="#9EC3ED" fspArray={["1", "2"]} index={0} />
          <FspItem fspName="Regulatory Examinations" fspColor="#C19EED" fspArray={["1", "2"]} index={1} />
        </LeftComponent>
      </div>
      <div className="right-column" ref={rightColumnRef}>
        <RightComponent>
          {dimensions.width > 0 && (
            <List
              height={dimensions.height}
              width={dimensions.width}
              layout="horizontal"
              itemCount={monthColumns.length}
              itemSize={100}
              ref={listRef}
            >
              {Row}
            </List>
          )}
        </RightComponent>
      </div>
    </div>
  );
};

export default BrokerTimeline;
