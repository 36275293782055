import ApiService from "../../../../../auth/ApiService";

const fetchData = async (sternaAuth, options = () => { }, start = () => { }, end = () => { }, endpoint = 'brokerage') => {
  try {
    start();
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);
    const response = genericFetch(
      endpoint,
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === 'Success',
      (rsp) => rsp?.data?.brokerages.map((brokerageItem) => {
        return {
          value: brokerageItem.id,
          label: `${brokerageItem.name} | ${brokerageItem.fspNumber}`
        };
      }),
      (rwd) => options(rwd),
      start,
      end,
      (error) => console.log('Error fetching brokerages: ', error)
    );
    return response?.data?.brokerages;
  } catch (error) {
    console.log('Error fetching brokerages: ', error);
    return null;
  } finally {
    end();
  }
};

const genericFetch = async (
  endpoint,
  parameters,
  predicate = () => { },
  reworkMethod = () => { },
  setMethod = () => { },
  preMethod = () => { },
  postMethod = () => { },
  errorMethod = () => { }
) => {
  try {
    preMethod();
    const response = await ApiService.get(endpoint, parameters);
    if (predicate(response)) {
      const reworkedData = reworkMethod(response);
      setMethod(reworkedData);
      return response;
    }
  } catch (error) {
    errorMethod(error);
    return undefined;
  } finally {
    postMethod();
  }
}

export { genericFetch };
export default fetchData;