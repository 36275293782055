import React, { useState } from "react";
import BrokerQualificationItem from "../../../../../../../components/listitems/detailitems/BrokerQualificationItem";
import SingleCard from "../../../../../../../components/cards/SingleCard";
import Popup from "../../../../../../../components/widgets/Popup";
import DualColumn from "../../../../../../../components/containers/DualColumn";
import FancySubmit from "../../../../../../../components/buttons/FancySubmit";
import { removeQualificationRecord } from "../../handlers/qualificationHandlers";
import { faInstitution } from "@fortawesome/free-solid-svg-icons";

const QualificationsList = ({
  qualifications,
  setQualifications = () => { }
}) => {
  const [indexOfQualificationRecordToRemove, setIndexOfQualificationRecordToRemove] = useState();
  const [removeQualificationPrompt, setRemoveQualificationPrompt] = useState(false);

  const handleQualificationRecordRemovePrompt = (recordIndex) => {
    setRemoveQualificationPrompt(true);
    setIndexOfQualificationRecordToRemove(recordIndex);
  }

  const handleQualificationRecordRemove = (recordIndex) => {
    const result = removeQualificationRecord(qualifications, recordIndex);

    if (result) {
      setQualifications(result.newQualifications);
    }

    setIndexOfQualificationRecordToRemove(undefined);
    setRemoveQualificationPrompt(false);
  }

  return (
    <>
      <div className='brokers-new-name-box brokers-new-name-box-bottom-margin'>
        <h4>Qualification History</h4>
      </div>
      {
        qualifications?.length > 0 ? qualifications.map((qualification, index) => (
          <BrokerQualificationItem
            key={index}
            qualification={qualification}
            lastItem={qualifications.length - 1 === index}
            onDelete={handleQualificationRecordRemovePrompt}
            index={index}
          />
        )) :
          <>
            <SingleCard icon={faInstitution} value="No Record!" iconColor='#e57373' />
            <div style={{ marginBottom: 15 }} />
          </>
      }
      <Popup isLoading={removeQualificationPrompt}>
        <p>Are you sure you wish to remove this Qualification Record?</p>
        <DualColumn
          leftChildren={<FancySubmit title='Yes' onClick={() => handleQualificationRecordRemove(indexOfQualificationRecordToRemove)} />}
          rightChildren={<FancySubmit title='No' onClick={() => {
            setIndexOfQualificationRecordToRemove(undefined);
            setRemoveQualificationPrompt(false);
          }} />}
        />
      </Popup>
    </>
  );
};

export default QualificationsList;
