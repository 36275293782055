import { createBrowserRouter } from 'react-router-dom';
import { DefaultRoutes } from './routes/DefaultRoutes';
import { FsbRoutes } from './routes/FsbRoutes';

const profileDefault = "default";
const profileFsb = "fsb";

export class SternaNavigator {
  constructor() {
    this.isSignedIn = false;
    this.navigationProfile = profileFsb;
    this.subscribers = [];
  }

  subscribeToChanges(callback) {
    this.subscribers.push(callback);
    return () => {
      this.subscribers = this.subscribers.filter(sub => sub !== callback);
    };
  }

  notifySubscribers() {
    this.subscribers.forEach(callback => callback(this.setupRoutes()));
  }

  toggleSignIn() {
    this.isSignedIn = !this.isSignedIn;
    this.notifySubscribers();
  }

  setNavigationProfile(profile) {
    if (profile === profileDefault || profile === profileFsb) {
      this.navigationProfile = profile;
    } else {
      this.navigationProfile = profileDefault;
    }
    this.notifySubscribers();
  }

  getSignInState() {
    return this.isSignedIn;
  }

  setupRoutes() {
    let routes = [];
    switch (this.navigationProfile) {
      case profileDefault:
        routes.push(...FsbRoutes); // Change this to default when there are more than one profile
        break;
      case profileFsb:
        routes.push(...FsbRoutes);
        break;
      default:
        routes.push(...DefaultRoutes);
        break;
    }

    return createBrowserRouter(routes);
  }
}