import React from 'react';
import SkeletonLoaderList from '../widgets/SkeletonLoaderList';

const SideListContainer = ({loading, nobg = false, topborder = false, semitransparentbg = false, children}) => {
  let containerClass = nobg ? "sidelistcontainer no-bg" : "sidelistcontainer";
  containerClass = topborder ? `${containerClass} top-border` : containerClass;
  containerClass = semitransparentbg ? `${containerClass} semi-transparent-bg` : containerClass;

  return (
    <div className={containerClass}>
      {loading ? <SkeletonLoaderList count={3} /> : <ul>{children}</ul>}
    </div>
  );
}

export default SideListContainer;
