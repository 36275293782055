import React, { useState, useEffect } from 'react';
import ListItemBase from '../../genericitems/ListItemBase';
import '../../../../scss/main.scss';
import JellyCheckbox from '../../../formcomponents/checkboxes/JellyCheckbox';

const ListEntryItem = ({ entry, resetCheckbox, index, prefix = "entry", onClick = () => { } }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [resetCheckbox]);

  const selectItem = (event) => {
    setChecked(event.target.checked);
  }

  return (
    <ListItemBase
      leftComponent={<JellyCheckbox id={`${prefix}-jelly-checkbox-${index}`} checked={checked} onChange={selectItem} />}
      onClick={() => onClick(entry)}
    >
      <p>{entry?.name ? entry.name : "No name"}</p>
    </ListItemBase>
  );
}

export default ListEntryItem;
