import React from "react";
import "./apple-switch.scss";

const AppleSwitch = ({ checked, onChange = () => { }, activeColor = '#59c76b' }) => {
  const labelStyle = {
    '--slider-checked-background': activeColor
  };

  return (
    <label className="apple-switch" style={labelStyle}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="apple-slider"></span>
    </label>
  );
};

export default AppleSwitch;
