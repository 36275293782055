import React from "react";
import Select from 'react-select';
import "./stylish-select.scss";

const StylishSelect = ({
  options,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  name,
  onChange,
  value,
  controlStyles = {},
  menuStyles = {}
}) => {
  const inputStyle = {
    control: (styles) => ({
      ...styles,
      borderRadius: 10,
      width: 200,
      fontSize: 14,
      overflow: 'hidden',
      ...controlStyles
    }),
    valueContainer: (styles) => ({
      ...styles,
      backgroundColor: '#f8fafc'
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      backgroundColor: '#f8fafc'
    }),
    menu: (styles) => ({
      ...styles,
      fontSize: 14,
      ...menuStyles
    })
  };

  return (
    <div className="stylish-select-group">
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={options[0]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        options={options}
        onChange={onChange}
        value={value}
        styles={inputStyle}
      />
    </div>
  );
};

export default StylishSelect;
