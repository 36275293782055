import { DateTime } from 'luxon';
import ErrorHandling from '../../../../../../utils/errors/ErrorHandling';
import {
  isStartDateGreaterThanEndDate
} from '../../../../../../utils/datetime/dateTimeUtils';

export const addQualificationRecord = (
  qualificationName, institution, achievedDate, fscaRecognised, qualifications
) => {
  try {
    if (!qualificationName) {
      return ErrorHandling.toastifyError("Provide a qualification name!");
    }
    if (qualificationName === '') {
      return ErrorHandling.toastifyError("Qualification name cannot be empty!");
    }
    if (!institution) {
      return ErrorHandling.toastifyError("Specify an institution!");
    }
    if (institution === '') {
      return ErrorHandling.toastifyError("Institution cannot be empty!");
    }
    if (!achievedDate) {
      return ErrorHandling.toastifyError("Provide a valid qualification date!");
    }
    if (isStartDateGreaterThanEndDate(achievedDate)) {
      return ErrorHandling.toastifyError("Qualification date cannot be in the future!");
    }

    const record = {
      qualificationName,
      institution,
      dateOfQualificationAchieved: achievedDate,
      recognised: fscaRecognised
    }

    const newQualifications = [...qualifications, record];
    newQualifications.sort((a, b) => new Date(a.dateOfQualificationAchieved) - new Date(b.dateOfQualificationAchieved));

    return {
      newQualifications,
      resetFields: {
        qualificationName: '',
        institution: '',
        achievedDate: DateTime.now().toJSDate(),
        fscaRecognised: false
      }
    };
  } catch (error) {
    console.error('Failed to add qualification record citing error: ', error);
    ErrorHandling.toastifyError(error.message || "Failed to add record!");
    return null; // Indicates failure
  }
};

export const removeQualificationRecord = (
  qualifications, recordIndex
) => {
  try {
    const newQualifications = qualifications.filter((_, index) => index !== recordIndex);
    newQualifications.sort((a, b) => new Date(a.dateOfQualificationAchieved) - new Date(b.dateOfQualificationAchieved));

    const stateUpdates = {
      newQualifications
    };

    return stateUpdates;
  } catch (error) {
    console.error("Failed to remove item from qualifications array: ", error);
    return null; // Indicates failure
  }
};
