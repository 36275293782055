import React from "react";
import "./broker-employment-item.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faCalendarCheck, faCalendarTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const BrokerEmploymentItem = ({ employment, index, lastItem = false, isDofa = false, onDelete = (i) => console.log(i) }) => {
  var appointmentDate = employment?.dateOfAppointment ? new Date(employment?.dateOfAppointment).toISOString().split('T')[0] : "";
  var terminationDate = employment?.dateOfTermination ? new Date(employment?.dateOfTermination).toISOString().split('T')[0] : "";
  var containerStyle = lastItem ?
    "broker-employment-item-container broker-employment-item-container-bottom-margin" :
    "broker-employment-item-container";

  return (
    <div className={containerStyle}>
      <div className="broker-employment-item-icon-box">
        <FontAwesomeIcon icon={faBriefcase} className="broker-employment-item-icon-style" />
      </div>
      <div className="broker-employment-item-detail-box">
        <h4>{employment?.brokerageName ?? "No Name"}</h4>
        <div className="broker-employment-item-history-box">
          <div className="broker-employment-item-date-box">
            <FontAwesomeIcon icon={faCalendarCheck} className="broker-employment-item-date-icon-style" />
            <p>{appointmentDate}</p>
          </div>
          {
            terminationDate !== "" ? <>
              <div className="broker-employment-item-date-box broker-employment-item-date-box-offset">
                <FontAwesomeIcon icon={faCalendarTimes} className="broker-employment-item-date-icon-style" />
                <p>{terminationDate}</p>
              </div>
            </> : <>
              <div className="broker-employment-item-date-box broker-employment-item-date-box-offset">
                <p className="success-text">Currently Active</p>
              </div>
            </>
          }
          {
            isDofa ? <>
              <div className="broker-employment-item-date-box broker-employment-item-date-box-offset">
                <p className="warning-text">DOFA</p>
              </div>
            </> : null
          }
        </div>
      </div>
      <div className="broker-employment-item-close-box" onClick={() => onDelete(index)}>
        <FontAwesomeIcon icon={faTrashAlt} className="broker-employment-item-close-icon-style" />
      </div>
    </div>
  );
}

export default BrokerEmploymentItem;
