import React from "react";
import "./list-item-base.scss";

const ListItemBase = ({ leftComponent, children, onClick = () => { } }) => {
  return (
    <div className="base-list-item" onClick={onClick}>
      <div className="left-space">
        {leftComponent}
      </div>
      <div className="right-space">
        {children}
      </div>
    </div>
  );
}

export default ListItemBase;
