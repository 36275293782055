import ApiService from "../../../../../../../auth/ApiService";
import ErrorHandling from "../../../../../../../utils/errors/ErrorHandling";

export const createNewCompetencyAssessment = async (
  vaoProductSpecificTraining,
  vaoCob,
  vaoRegulatoryExam,
  vaoRecognisedQualification,
  vaoSupervisionRequired,
  vaoCpd,
  vaoCms,
  vaoExperience,
  vaoMieVerification,
  vaoFicTfsList,
  remarkProductSpecificTraining,
  remarkCob,
  remarkRegulatoryExam,
  remarkRecognisedQualification,
  remarkSupervisionRequired,
  remarkCpd,
  remarkCms,
  remarkExperience,
  remarkMieVerification,
  remarkFicTfsList,
  complianceRemarks,
  brokerId,
  brokerName,
  sternaAuth
) => {
  try {
    const remarkArray = [
      { key: "PRODUCT SPECIFIC TRAINING", value: remarkProductSpecificTraining },
      { key: "CLASS OF BUSINESS (COB)", value: remarkCob },
      { key: "REGULATORY EXAMINATION", value: remarkRegulatoryExam },
      { key: "RECOGNISED QUALIFICATION", value: remarkRecognisedQualification },
      { key: "SUPERVISION AGREEMENT & ACTIVITIES (IF APPLICABLE)", value: remarkSupervisionRequired },
      { key: "CONTINUOUS PROFESSIONAL DEVELOPMENT (CPD) - IF APPLICABLE", value: remarkCpd },
      { key: "COUNCIL FOR MEDICAL SCHEMES (CMS) - IF APPLICABLE", value: remarkCms },
      { key: "EXPERIENCE", value: remarkExperience },
      { key: "MIE VERIFICATION FOR QUALIFICATION", value: remarkMieVerification },
      { key: "TARGETED FINANCIAL SANCTIONS SCREENING (FIC TFS LIST)", value: remarkFicTfsList }
    ];
  
    const outcomeArray = [
      { key: "Product Specific Training", value: vaoProductSpecificTraining },
      { key: "CLASS OF BUSINESS (COB)", value: vaoCob },
      { key: "REGULATORY EXAMINATION", value: vaoRegulatoryExam },
      { key: "RECOGNISED QUALIFICATION", value: vaoRecognisedQualification },
      { key: "SUPERVISION AGREEMENT & ACTIVITIES (IF APPLICABLE)", value: vaoSupervisionRequired },
      { key: "CONTINUOUS PROFESSIONAL DEVELOPMENT (CPD) - IF APPLICABLE", value: vaoCpd },
      { key: "COUNCIL FOR MEDICAL SCHEMES (CMS) - IF APPLICABLE", value: vaoCms },
      { key: "EXPERIENCE", value: vaoExperience },
      { key: "MIE VERIFICATION FOR QUALIFICATION", value: vaoMieVerification },
      { key: "TARGETED FINANCIAL SANCTIONS SCREENING (FIC TFS LIST)", value: vaoFicTfsList }
    ];
  
    let remarkData = [];
  
    remarkArray.forEach(remark => {
      const complianceRemark = complianceRemarks.find((cr) => cr.isCatOneSubCategory === false && cr.remark === remark.key);
      const remarkRecord = {
        comment: remark.value ?? "",
        complianceRemark: {
          id: complianceRemark?.id,
          remark: complianceRemark?.remark,
          isCatOneSubCategory: false
        }
      };
      remarkData = [...remarkData, remarkRecord];
    });
  
    outcomeArray.forEach(outcome => {
      const complianceRemark = complianceRemarks.find((cr) => cr.isCatOneSubCategory === true && cr.remark === outcome.key);
      const remarkRecord = {
        isChecked: outcome.value ?? false,
        complianceRemark: {
          id: complianceRemark?.id,
          remark: complianceRemark?.remark,
          isCatOneSubCategory: true
        }
      };
      remarkData = [...remarkData, remarkRecord];
    });
  
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);
  
    const competencyAssessmentRecord = {
      apiKey: process.env.REACT_APP_MASTER_API_KEY,
      userId: claims?.UserId,
      competencyAssessment: {
        brokerId,
        name: `${brokerName} ${Date.now()}`,
        complianceOfficerRemarks: remarkData
      }
    };
  
    const createResponse = await ApiService.post("competencyAssessment", competencyAssessmentRecord);

    if (!createResponse?.data?.competencyAssessment?.id) {
      console.log(createResponse?.data?.competencyAssessment);
      return ErrorHandling.toastifyError("Failed to create assessment!");
    }

    const updatedAssessmentRecord = {
      apiKey: process.env.REACT_APP_MASTER_API_KEY,
      userId: claims?.UserId,
      competencyAssessment: {
        id: createResponse?.data?.competencyAssessment?.id,
        brokerId,
        name: `${brokerName} ${Date.now()}`,
        complianceOfficerRemarks: remarkData
      }
    };
    const updateResponse = await ApiService.put("competencyAssessment", updatedAssessmentRecord);
    console.log('Response: ', updateResponse);
  
    if (updateResponse?.data?.message !== 'Update Success') {
      console.log(updateResponse?.data?.message);
      return ErrorHandling.toastifyError(updateResponse?.data?.message ?? "Failed to create new assessment!");
    }
    if (!updateResponse?.data?.competencyAssessment?.id) {
      console.log(updateResponse?.data?.competencyAssessment);
      return ErrorHandling.toastifyError("Failed to create assessment!");
    }

    return ErrorHandling.toastifySuccess("Assessment created successfully!");
  } catch (error) {
    console.error("An error occurred while trying to add a new Assessment: ", error);
    return ErrorHandling.toastifyError("Failed to create assessment! Unknown Error!");
  }
}