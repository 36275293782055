import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../../auth/AuthContext';
import { faHandsHolding, faCalendarCheck, faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import StylishSelect from "../../../../../../../components/formcomponents/inputs/StylishSelect";
import StylishDateTimePicker from '../../../../../../../components/formcomponents/inputs/StylishDateTimePicker';
import FancySubmit from '../../../../../../../components/buttons/FancySubmit';
import { genericFetch } from '../../brokerService';
import '../../brokers-new.scss';
import { addCobRecord } from "../../handlers/cobHandlers";

const CobRecordForm = ({
  cobs,
  exams,
  qualifications,
  dofaDate,
  setCobs = () => { }
}) => {
  const { sternaAuth } = useAuth();

  const [options, setOptions] = useState([{ label: "No Option", value: "0" }]);
  const [lists, setLists] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cob, setCob] = useState();

  const [dofaStart, setDofaStart] = useState();
  const [dofaEnd, setDofaEnd] = useState();

  useEffect(() => {
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);

    // Fetch lists:
    genericFetch(
      "list",
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === 'Success',
      (rsp) => rsp?.data?.lists,
      (data) => setLists(data),
      () => { },
      () => { },
      (err) => console.log('Failed to fetch lists: ', err)
    );

    // Fetch list items for Product Subcategories:
    genericFetch(
      "list",
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === 'Success',
      (rsp) => {
        const list = rsp?.data?.lists?.find((lst) => lst.name === 'Product Subcategories');
        return list ? list.listItems?.map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        }) : [];
      },
      (data) => setOptions(data),
      () => setLoading(true),
      () => setLoading(false),
      (err) => console.log('Failed to fetch list items for Product Subcategories: ', err)
    );

    // Fetch list item associations:
    genericFetch(
      "list/list-item/association",
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === '',
      (rsp) => rsp?.data?.listItemAssociations,
      (data) => setAssociations(data),
      () => { },
      () => { },
      (err) => console.log('Failed to fetch list item associations: ', err)
    );
  }, [sternaAuth]);

  useEffect(() => {
    console.log('Lists: ', lists);
  }, [lists]);

  useEffect(() => {
    console.log('Associations: ', associations);
  }, [associations]);

  const handleCobSelect = (selection) => {
    setCob(selection);
  }

  const handleDofaStartChange = (newDate) => {
    setDofaStart(newDate);
  }

  const handleDofaEndChange = (newDate) => {
    setDofaEnd(newDate);
  }

  const handleCobRecordAdd = () => {
    const result = addCobRecord(
      cob,
      cobs,
      dofaDate,
      dofaStart,
      dofaEnd,
      exams,
      qualifications,
      associations,
      lists
    );
    if (result) {
      setCobs(result.newCobs);
      // Reset fields
      setCob(result.resetFields.cob);
      setDofaStart(result.resetFields.dofaStart);
      setDofaEnd(result.resetFields.dofaEnd);
    }
  }

  return (
    <>
      <div className='brokers-new-name-box'>
        <h4>Add Close of Business</h4>
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label' style={{ width: 200 }}>
          <p>Select Product Subcategory:</p>
        </div>
        <StylishSelect
          icon={faHandsHolding}
          options={options}
          isLoading={loading}
          isClearable
          isSearchable
          name="broker-cob-select"
          onChange={handleCobSelect}
          value={cob}
          controlStyles={{ width: 200 }}
          menuStyles={{ zIndex: 100 }}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Product Subcategory DOFA:</p>
        </div>
        <StylishDateTimePicker
          date={dofaStart}
          onSelect={() => { }}
          onChange={handleDofaStartChange}
          icon={faCalendarCheck}
          groupStyles={{ width: 200 }}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>DOFA Termination:</p>
        </div>
        <StylishDateTimePicker
          date={dofaEnd}
          onSelect={() => { }}
          onChange={handleDofaEndChange}
          icon={faCalendarXmark}
          groupStyles={{ width: 200 }}
        />
      </div>
      <div className='brokers-new-name-box align-right'>
        <FancySubmit title='Add COB Record' onClick={handleCobRecordAdd} />
      </div>
    </>
  );
}

export default CobRecordForm;
