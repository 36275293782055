import React from "react";
import FancySwitch from "../../../../../../../components/toggleswitches/FancySwitch";
import StylishInput from "../../../../../../../components/formcomponents/inputs/StylishInput";
import '../../assessments-new.scss';

const Remark = ({
  title,
  criteria,
  remark,
  icon,
  yesStyle,
  noStyle,
  yesText = " [YES]",
  noText = " [NO]",
  criteriaChange = () => { },
  remarkChange = () => { },
  remarkPlaceholder = "placeholder",
  colorLeft = "#F66",
  colorRight = "#667F33"
}) => {
  const handleCriteriaChange = (event) => {
    criteriaChange(event.target.checked);
  }

  const handleRemarkChange = (event) => {
    remarkChange(event.target.value);
  }

  return (
    <>
      <div className='assessment-new-input-pair'>
        <div className='assessment-new-input-label' style={{ width: 300 }}>
          <p>{title}: <span style={criteria ? yesStyle : noStyle}>{criteria ? noText : yesText}</span></p>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
          <FancySwitch checked={criteria} onChange={handleCriteriaChange} colorLeft={colorLeft} colorRight={colorRight} />
        </div>
      </div>
      <StylishInput icon={icon} type="text" value={remark} onChange={handleRemarkChange} placeholder={remarkPlaceholder} groupStyles={{ maxWidth: 410 }} />
    </>
  );
}

export default Remark;
