import React from "react";
import ListItemBase from "../../genericitems/ListItemBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

const ListsItem = ({ list, onClick = () => {} }) => {
  const listname = list?.name ?? 'Not named';
  
  return (
    <ListItemBase
      leftComponent={<FontAwesomeIcon icon={faList} color="#7D8973" />}
      onClick={() => onClick(list)}
    >
      <p>{listname}</p>
    </ListItemBase>
  );
}

export default ListsItem;
