import ErrorHandling from "../../../../../../utils/errors/ErrorHandling";
import ApiService from "../../../../../../auth/ApiService";

export const handleFormSubmit = async (record, sternaAuth) => {
  try {
    if (record.broker.name === "") throw new Error("Broker name must be provided!");
    if (record.broker.surname === "") throw new Error("Broker surname must be provided!");
    if (record.broker.idNo === "") throw new Error("Broker Id is required!");
    if (record.broker.brokerageId === undefined) throw new Error("Select a Brokerage!");
    // Improve the validation!

    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);

    const requestBody = {
      apiKey: process.env.REACT_APP_MASTER_API_KEY,
      userId: claims?.UserId,
      broker: {
        ...record.broker
      }
    }

    console.log('Request Body: ', requestBody);
    const response = await ApiService.post(`brokerage/${record.broker.brokerageId}/broker`, requestBody);

    if (response?.data?.message !== 'Create Success') {
      console.log(response?.data?.message);
      return ErrorHandling.toastifyError(response?.data?.message ?? "Failed to create Broker!");
    }
    if (!response?.data?.broker?.id) {
      console.log(response?.data?.broker);
      return ErrorHandling.toastifyError("Failed to create Broker!");
    }

    // Return success message or other relevant data
    return {
      success: true,
      message: "New Broker Created!",
      resetFields: {
        brokerName: "",
        brokerSurname: "",
        brokerId: "",
        brokerage: undefined
      }
    };
  }
  catch (error) {
    console.error("An error occurred while trying to add a Broker: ", error);
    return {
      success: false,
      message: error.message || "Error adding Broker!",
      resetFields: null
    };
  }
};
