import React from "react";
import TitleSection from "../../sections/itemdetailsections/other/header/TitleSection";
import TitleSectionComplete from "../../sections/itemdetailsections/other/header/TitleSectionComplete";
import NoEntitySelected from "../../listitems/genericitems/NoEntitySelected";
import './item-detail-with-title.scss';

const ItemDetailWithTitle = ({
  entityDetail,
  title,
  icon,
  entityType = "Entity",
  isDelete = true,
  onDelete = () => { },
  canComplete = false,
  onComplete = () => { },
  titleChildren,
  children
}) => {
  if (!entityDetail) {
    return <NoEntitySelected entity={entityType} />
  }

  const TitleComponent = isDelete ? TitleSection : TitleSectionComplete;
  const additionalProps = isDelete ? { deleteMethod: onDelete } : { canComplete, completeMethod: onComplete };

  return (
    <>
      <TitleComponent lighttitle title={title} icon={icon} {...additionalProps}>
        {titleChildren}
      </TitleComponent>
      <div className="content-container">
        {children}
      </div>
    </>
  );
}

export default ItemDetailWithTitle;
