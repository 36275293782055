import React from "react";
import "./fancy-confirm.scss";

const FancyConfirm = ({ title = "Confirm", onClick = () => {} }) => {
  return (
    <button className="fancy-confirm-button" onClick={onClick}>
      <span className="circle1"></span>
      <span className="circle2"></span>
      <span className="circle3"></span>
      <span className="circle4"></span>
      <span className="circle5"></span>
      <span className="text">{title}</span>
    </button>
  );
}

export default FancyConfirm;
