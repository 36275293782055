import React, { useState } from 'react';
import ApiService from '../../../../../auth/ApiService';
import ErrorHandling from '../../../../../utils/errors/ErrorHandling';
import { useAuth } from '../../../../../auth/AuthContext';
import { faBuilding, faPencilAlt, faHashtag } from '@fortawesome/free-solid-svg-icons';
import ItemDetailWithTitle from '../../../../../components/containers/ItemDetailWithTitle';
import MainContent from '../../../../../components/containers/MainContent';
import StylishInput from '../../../../../components/formcomponents/inputs/StylishInput';
import FancySubmit from '../../../../../components/buttons/FancySubmit';
import FullPageLoader from '../../../../../components/widgets/FullPageLoader';
import "./brokerages-new.scss";

const BrokeragesNew = () => {
  const { sternaAuth } = useAuth();
  const [brokerageName, setBrokerageName] = useState("");
  const [fspNumber, setFspNumber] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleBrokerageNameChange = (event) => {
    setBrokerageName(event.target.value);
  }

  const handleFspNumberChange = (event) => {
    setFspNumber(event.target.value);
  }

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const token = sternaAuth.getToken();
      const claims = sternaAuth.parseJwt(token);
      const response = await ApiService.post('brokerage', {
        apiKey: process.env.REACT_APP_MASTER_API_KEY,
        userId: claims?.UserId,
        brokerage: {
          name: brokerageName,
          fspNumber
        }
      });
      if (response?.data?.message !== "Create Success") {
        console.log(response?.data?.message);
        return ErrorHandling.toastifyError(response?.data?.message ?? "Failed to create Brokerage!");
      }
      setBrokerageName("");
      setFspNumber("");
      return ErrorHandling.toastifySuccess("New Brokerage Created!");
    } catch (error) {
      console.error("An error has occurred: ", error);
      return ErrorHandling.toastifyError("Failed to create Brokerage!");
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <MainContent>
      <ItemDetailWithTitle
        title="Add New Brokerage"
        entityDetail={{ placeholder: "none" }}
        icon={faBuilding}
        isDelete={false}
      >
        <div className='brokerage-form'>
          <StylishInput icon={faPencilAlt} type="text" value={brokerageName} onChange={handleBrokerageNameChange} placeholder="brokerage name" />
          <StylishInput icon={faHashtag} type="text" value={fspNumber} onChange={handleFspNumberChange} placeholder="fsp number" />
          <FancySubmit title="Add Brokerage" onClick={handleSubmit} />
        </div>
      </ItemDetailWithTitle>
      <FullPageLoader isLoading={submitting} />
    </MainContent>
  );
}

export default BrokeragesNew;
