import React from "react";
import "./fsp-item.scss";

const FspItem = ({ fspColor = "#f66", fspName, fspArray, index }) => {
  const style = {
    "--box-color": fspColor
  };

  const firstElementStyle = index === 0 ? {
    marginTop: 10
  } : {}

  const fspItemClassName = index === fspArray.length - 1 ? "fsp-item fsp-item-last" : "fsp-item";

  return (
    <div className={fspItemClassName} style={firstElementStyle}>
      <div className="fsp-item-color-block" style={style}></div>
      <div className="fsp-item-name">
        <p>{fspName}</p>
      </div>
    </div>
  );
};

export default FspItem;
