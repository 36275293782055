import React, { useState } from "react";
import BrokerExaminationItem from "../../../../../../../components/listitems/detailitems/BrokerExaminationItem";
import SingleCard from "../../../../../../../components/cards/SingleCard";
import Popup from "../../../../../../../components/widgets/Popup";
import DualColumn from "../../../../../../../components/containers/DualColumn";
import FancySubmit from "../../../../../../../components/buttons/FancySubmit";
import { removeExamRecord } from "../../handlers/examinationHandlers";
import { faScroll } from "@fortawesome/free-solid-svg-icons";

const ExaminationList = ({
  examinations,
  setExaminations = () => { }
}) => {
  const [indexOfExaminationRecordToRemove, setIndexOfExaminationRecordToRemove] = useState();
  const [removeExaminationPrompt, setRemoveExaminationPrompt] = useState(false);

  const handleExaminationRecordRemovePrompt = (recordIndex) => {
    setRemoveExaminationPrompt(true);
    setIndexOfExaminationRecordToRemove(recordIndex);
  }

  const handleExaminationRecordRemove = (recordIndex) => {
    const result = removeExamRecord(examinations, recordIndex);

    if (result) {
      setExaminations(result.newExaminations);
    }

    setIndexOfExaminationRecordToRemove(undefined);
    setRemoveExaminationPrompt(false);
  }

  return (
    <>
      <div className='brokers-new-name-box brokers-new-name-box-bottom-margin'>
        <h4>Examination History</h4>
      </div>
      {
        examinations?.length > 0 ? examinations.map((examination, index) => (
          <BrokerExaminationItem
            key={index}
            examination={examination}
            lastItem={examinations.length - 1 === index}
            onDelete={handleExaminationRecordRemovePrompt}
            index={index}
          />
        )) :
          <>
            <SingleCard icon={faScroll} value="No Record!" iconColor='#e57373' />
            <div style={{ marginBottom: 15 }} />
          </>
      }
      <Popup isLoading={removeExaminationPrompt}>
        <p>Are you sure you wish to remove this Examination Record?</p>
        <DualColumn
          leftChildren={<FancySubmit title='Yes' onClick={() => handleExaminationRecordRemove(indexOfExaminationRecordToRemove)} />}
          rightChildren={<FancySubmit title='No' onClick={() => {
            setIndexOfExaminationRecordToRemove(undefined);
            setRemoveExaminationPrompt(false);
          }} />}
        />
      </Popup>
    </>
  );
};

export default ExaminationList;
