import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import ListItemBase from "../../genericitems/ListItemBase";

const BrokerItem = ({ broker, onClick = () => {} }) => {
  const brokername = broker?.name ?? 'Name';
  const brokersurname = broker?.surname ?? 'Surname';

  return (
    <ListItemBase
      leftComponent={<FontAwesomeIcon icon={faUserAlt} color="#7D8973" />}
      onClick={() => onClick(broker)}
    >
      <p>{`${brokername} ${brokersurname}`}</p>
    </ListItemBase>
  );
}

export default BrokerItem;
