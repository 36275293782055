import React from "react";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import SingleCard from "../../../cards/SingleCard";

const NoEntitySelected = ({ entity = "Entity" }) => {
  return (
    <SingleCard icon={faInbox} value={`No ${entity} selected`} />
  );
}

export default NoEntitySelected;
