const menuItems = [
  {
    category: 'Competency',
    items: [
      { label: 'Assessments', path: '/fsp/dashboard/competency/assessments/all' },
      { label: 'Lists', path: '/fsp/dashboard/competency/lists/all' }
    ],
  },
  {
    category: 'Management',
    items: [
      { label: 'Brokerages', path: '/fsp/dashboard/management/brokerages/all' },
      { label: 'Brokers', path: '/fsp/dashboard/management/brokers/all' }
    ],
  }
];

export default menuItems;