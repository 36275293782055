import React from 'react';
import SideImageCard from '../../../../../../../components/cards/SideImageCard';
import NumberValueCard from '../../../../../../../components/cards/NumberValueCard';
import DualColumnAdjustable from '../../../../../../../components/containers/DualColumnAdjustable';
import examinationsImage from '../../../../../../../images/in-app/cardimages/exams.png';
import ExaminationRecordForm from './ExaminationRecordForm';
import ExaminationList from './ExaminationList';

const ExaminationsCard = ({
  examinations,
  setExaminations = () => { }
}) => {
  return (
    <SideImageCard img={examinationsImage} imgAlt="Examinations">
      <div className='brokers-new-info-form'>
        <div className='brokers-new-name-box'>
          <h4>Examinations</h4>
        </div>
        <DualColumnAdjustable
          leftFlex={1}
          leftContent={<>
            <NumberValueCard
              value={examinations?.length ?? 0}
              valueColor={examinations?.length > 0 ? '#72cc72' : '#e57373'}
              title={examinations?.length === 1 ? 'Examination' : 'Examinations'}
              titleColor='#7D8973'
            />
          </>}
          rightFlex={1}
          rightContent={<>
          </>}
        />
        <hr />
        <ExaminationRecordForm
          examinations={examinations}
          setExaminations={setExaminations}
        />
        <hr />
        <ExaminationList
          examinations={examinations}
          setExaminations={setExaminations}
        />
      </div>
    </SideImageCard>
  );
};

export default ExaminationsCard;