import MainHeader from '../../../components/sections/MainHeader';
import '../../../scss/components/sections.scss';
import '../../../scss/components/navbar.scss';
import './home.scss';
import loginLogo from '../../../images/logos/aquila-logo-green-cropped.png';
import LoginSection from '../../../components/sections/LoginSection';

const Home = () => {
  return (
    <MainHeader>
      <div className='header-wrapper'>
        <div className='home-page-split-column'>
          <img className='login-logo' src={loginLogo} alt="Login Logo" />
        </div>
        <div className='home-page-split-column'>
          <LoginSection />
        </div>
      </div>
    </MainHeader>
  );
}

export default Home;