import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../auth/AuthContext';
import { faScroll, faBuildingUser } from '@fortawesome/free-solid-svg-icons';
import MainContent from '../../../../../components/containers/MainContent';
import fetchData, { genericFetch } from '../../../management/brokers/BrokersNew/brokerService';
import ItemDetailWithTitle from '../../../../../components/containers/ItemDetailWithTitle';
import './assessments-new.scss';
import SideImageCard from '../../../../../components/cards/SideImageCard';
import assessmentImage from "../../../../../images/in-app/cardimages/info.png";
import StylishSelect from '../../../../../components/formcomponents/inputs/StylishSelect';
import AssessmentForm from './components/AssessmentForm';

const AssessmentsNew = () => {
  const { sternaAuth } = useAuth();

  const [brokerage, setBrokerage] = useState();
  const [brokerages, setBrokerages] = useState([]);
  const [options, setOptions] = useState([{ value: "0", label: "No Brokerage" }]);
  const [loading, setLoading] = useState(true);

  const [broker, setBroker] = useState();
  const [brokerOptions, setBrokerOptions] = useState([{ value: "0", label: "No Broker" }]);
  const [loadingBrokers, setLoadingBrokers] = useState(false);

  useEffect(() => {
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);

    fetchData(
      sternaAuth,
      (availableOptions) => setOptions(availableOptions),
      () => setLoading(true),
      () => setLoading(false)
    );

    genericFetch(
      'brokerage',
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === 'Success',
      (rsp) => rsp?.data?.brokerages,
      (rwd) => setBrokerages(rwd),
      () => { },
      () => { },
      (error) => console.log('Error fetching brokerages: ', error)
    )
  }, [sternaAuth]);

  useEffect(() => {
    // If brokerages is undefined we should clear broker inputs:
    if (!options) {
      setBrokerOptions([{ value: "0", label: "No Broker" }]);
      setBroker(undefined);
      setLoadingBrokers(false);
      return;
    }

    // If brokerage is undefined...
    if (!brokerage) {
      return;
    }

    const foundBrokerage = brokerages.find(brk => brk.id === brokerage.value); // Obtain full record of the selected brokerage
    if (!foundBrokerage) {
      return;
    }

    setLoadingBrokers(true);
    if (foundBrokerage?.brokers) {
      const availableOptions = foundBrokerage.brokers.map(brokerItem => ({
        value: brokerItem.id,
        label: `${brokerItem.name} ${brokerItem.surname}`,
      }));
      setBrokerOptions(availableOptions);
    } else {
      setBrokerOptions([{ value: "0", label: "No Broker" }]);
      setBroker(undefined);
    }
    setLoadingBrokers(false);
  }, [brokerage, brokerages]);

  const handleBrokerageSelect = (selection) => {
    setBrokerage(selection);
  }

  const handleBrokerSelect = (selection) => {
    setBroker(selection);
  }

  useEffect(() => {
    console.log('Broker: ', broker);
  }, [broker]);

  return (
    <MainContent>
      <ItemDetailWithTitle
        title="Generate New Competency Assessment"
        entityDetail={{ placeholder: "none" }}
        icon={faScroll}
        isDelete={false}
      >
        <div className='assessment-form'>
          <SideImageCard img={assessmentImage} imgAlt="Assessment">
            <div className="assessment-new-info-form">
              <div className='assessment-new-name-box'>
                <h4>Assessment Selection Criteria</h4>
              </div>
              <div className='assessment-new-input-pair'>
                <div className='assessment-new-input-label' style={{ width: 100 }}>
                  <p>Select FSP:</p>
                </div>
                <StylishSelect
                  icon={faBuildingUser}
                  options={options}
                  isLoading={loading}
                  isClearable
                  isSearchable
                  name="broker-brokerage-select"
                  onChange={handleBrokerageSelect}
                  value={brokerage}
                  controlStyles={{ width: 300 }}
                  menuStyles={{ zIndex: 100 }}
                />
              </div>
              {
                brokerage ? <>
                  <div className='assessment-new-input-pair'>
                    <div className='assessment-new-input-label' style={{ width: 100 }}>
                      <p>Select Broker:</p>
                    </div>
                    <StylishSelect
                      icon={faBuildingUser}
                      options={brokerOptions}
                      isLoading={loadingBrokers}
                      isClearable
                      isSearchable
                      name="broker-select"
                      onChange={handleBrokerSelect}
                      value={broker}
                      controlStyles={{ width: 300 }}
                      menuStyles={{ zIndex: 100 }}
                    />
                  </div>
                </> : null
              }
            </div>
          </SideImageCard>
          {
            broker ? <AssessmentForm brokerId={broker.value} brokerName={broker.label} /> : null
          }
        </div>
      </ItemDetailWithTitle>
    </MainContent>
  );
}

export default AssessmentsNew;
