import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./stylish-input.scss";

const StylishInput = ({ icon, type, placeholder, value, onChange = () => {}, groupStyles = {}, inputStyles = {} }) => {
  return (
    <div className="stylish-group" style={{ ...groupStyles }}>
      <FontAwesomeIcon icon={icon} className="stylish-icon" />
      <input className="stylish-input" style={{ ...inputStyles }} type={type} placeholder={placeholder} value={value} onChange={onChange} />
    </div>
  );
}

export default StylishInput;
