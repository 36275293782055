import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../../../auth/AuthContext";
import SideImageCard from "../../../../../../../components/cards/SideImageCard";
import FancySubmit from "../../../../../../../components/buttons/FancySubmit";
import assessmentInfoImage from "../../../../../../../images/in-app/cardimages/assessment.png";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Remark from "../Remark";
import { genericFetch } from "../../../../../management/brokers/BrokersNew/brokerService";
import { createNewCompetencyAssessment } from "./assessmentHandlers";

const AssessmentForm = ({ brokerId, brokerName }) => {
  const { sternaAuth } = useAuth();

  const [vaoProductSpecificTraining, setVaoProductSpecificTraining] = useState(false);
  const [vaoCob, setVaoCob] = useState(false);
  const [vaoRegulatoryExam, setVaoRegulatoryExam] = useState(false);
  const [vaoRecognisedQualification, setVaoRecognisedQualification] = useState(false);
  const [vaoSupervisionRequired, setVaoSupervisionRequired] = useState(false);
  const [vaoCpd, setVaoCpd] = useState(false);
  const [vaoCms, setVaoCms] = useState(false);
  const [vaoExperience, setVaoExperience] = useState(false);
  const [vaoMieVerification, setVaoMieVerification] = useState(false);
  const [vaoFicTfsList, setVaoFicTfsList] = useState(false);

  const [remarkProductSpecificTraining, setRemarkProductSpecificTraining] = useState();
  const [remarkCob, setRemarkCob] = useState();
  const [remarkRegulatoryExam, setRemarkRegulatoryExam] = useState();
  const [remarkRecognisedQualification, setRemarkRecognisedQualification] = useState();
  const [remarkSupervisionRequired, setRemarkSupervisionRequired] = useState();
  const [remarkCpd, setRemarkCpd] = useState();
  const [remarkCms, setRemarkCms] = useState();
  const [remarkExperience, setRemarkExperience] = useState();
  const [remarkMieVerification, setRemarkMieVerification] = useState();
  const [remarkFicTfsList, setRemarkFicTfsList] = useState();

  const [complianceRemarks, setComplianceRemarks] = useState([]);

  const yesStyle = {
    color: "#667F33",
    fontWeight: "bold"
  };

  const noStyle = {
    color: "#F66",
    fontWeight: "bold"
  };

  useEffect(() => {
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);

    genericFetch(
      "complianceRemark",
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === 'Success',
      (rsp) => rsp?.data?.complianceRemarks,
      (data) => setComplianceRemarks(data),
      () => { },
      () => { },
      (err) => console.log('Failed to fetch compliance remarks: ', err)
    )
  }, []);

  const handleCreateAssessment = async () => {
    await createNewCompetencyAssessment(
      vaoProductSpecificTraining,
      vaoCob,
      vaoRegulatoryExam,
      vaoRecognisedQualification,
      vaoSupervisionRequired,
      vaoCpd,
      vaoCms,
      vaoExperience,
      vaoMieVerification,
      vaoFicTfsList,
      remarkProductSpecificTraining,
      remarkCob,
      remarkRegulatoryExam,
      remarkRecognisedQualification,
      remarkSupervisionRequired,
      remarkCpd,
      remarkCms,
      remarkExperience,
      remarkMieVerification,
      remarkFicTfsList,
      complianceRemarks,
      brokerId,
      brokerName,
      sternaAuth
    )
  }

  return (
    <>
      <SideImageCard img={assessmentInfoImage} imgAlt="AssessmentInfo">
        <div className='assessment-new-info-form'>
          <div className='assessment-new-name-box'>
            <h4>Verification Assessment Outcomes & Remarks</h4>
          </div>
          <Remark
            title="Product Specific Training"
            criteria={vaoProductSpecificTraining}
            remark={remarkProductSpecificTraining}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoProductSpecificTraining}
            remarkChange={setRemarkProductSpecificTraining}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Class of Business"
            criteria={vaoCob}
            remark={remarkCob}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoCob}
            remarkChange={setRemarkCob}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Regulatory Examination"
            criteria={vaoRegulatoryExam}
            remark={remarkRegulatoryExam}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoRegulatoryExam}
            remarkChange={setRemarkRegulatoryExam}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Recognised Qualification"
            criteria={vaoRecognisedQualification}
            remark={remarkRecognisedQualification}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoRecognisedQualification}
            remarkChange={setRemarkRecognisedQualification}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Supervision Agreement & Activities"
            criteria={vaoSupervisionRequired}
            remark={remarkSupervisionRequired}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoSupervisionRequired}
            remarkChange={setRemarkSupervisionRequired}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Continuous Professional Development"
            criteria={vaoCpd}
            remark={remarkCpd}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoCpd}
            remarkChange={setRemarkCpd}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Council for Medical Schemes"
            criteria={vaoCms}
            remark={remarkCms}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoCms}
            remarkChange={setRemarkCms}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Experience"
            criteria={vaoExperience}
            remark={remarkExperience}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoExperience}
            remarkChange={setRemarkExperience}
            remarkPlaceholder="remark"
          />
          <Remark
            title="MIE Verification for Qualification"
            criteria={vaoMieVerification}
            remark={remarkMieVerification}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoMieVerification}
            remarkChange={setRemarkMieVerification}
            remarkPlaceholder="remark"
          />
          <Remark
            title="Targeted Financial Sanctions Screening"
            criteria={vaoFicTfsList}
            remark={remarkFicTfsList}
            icon={faPencilAlt}
            yesStyle={yesStyle}
            noStyle={noStyle}
            criteriaChange={setVaoFicTfsList}
            remarkChange={setRemarkFicTfsList}
            remarkPlaceholder="remark"
          />
        </div>
      </SideImageCard>
      <FancySubmit title='Generate Assessment' onClick={handleCreateAssessment} />
    </>
  );
}

export default AssessmentForm;
