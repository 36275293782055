import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useAuth } from '../../../../../../../auth/AuthContext';
import StylishSelect from '../../../../../../../components/formcomponents/inputs/StylishSelect';
import StylishDateTimePicker from '../../../../../../../components/formcomponents/inputs/StylishDateTimePicker';
import FancySubmit from '../../../../../../../components/buttons/FancySubmit';
import { addExamRecord } from '../../handlers/examinationHandlers';
import { faLayerGroup, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { genericFetch } from '../../brokerService';
import '../../brokers-new.scss';

const ExaminationRecordForm = ({
  examinations,
  setExaminations = () => { }
}) => {
  const { sternaAuth } = useAuth();

  const [selectedLevel, setSelectedLevel] = useState();
  const [dateExaminationPassed, setDateExaminationPassed] = useState(DateTime.now().toJSDate());
  const [loading, setLoading] = useState(false);
  const [reOptions, setReOptions] = useState([{ value: "0", label: "No Option" }]);

  useEffect(() => {
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);
    
    // Fetch list items for Regulatory Exam:
    genericFetch(
      "list",
      `userId=${claims.UserId}`,
      (rsp) => rsp?.data?.message === 'Success',
      (rsp) => {
        const list = rsp?.data?.lists?.find((lst) => lst.name === 'Regulatory Exam');
        return list ? list.listItems?.map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        }) : [];
      },
      (data) => setReOptions(data),
      () => setLoading(true),
      () => setLoading(false),
      (err) => console.log('Failed to fetch list items for Regulatory Exam: ', err)
    );
  }, []);

  const handleLevelChange = (option) => {
    setSelectedLevel(option);
  }

  const handleExaminationDateChange = (newDate) => {
    setDateExaminationPassed(newDate);
  }

  const handleExaminationRecordAdd = () => {
    const result = addExamRecord(selectedLevel, dateExaminationPassed, examinations);
    if (result) {
      setExaminations(result.newExaminations);
      // Reset fields
      setSelectedLevel(result.resetFields.level);
      setDateExaminationPassed(result.resetFields.achievedDate);
    }
  }

  return (
    <>
      <div className='brokers-new-name-box'>
        <h4>Add RE Exam:</h4>
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Level:</p>
        </div>
        <StylishSelect
          icon={faLayerGroup}
          options={reOptions}
          isLoading={loading}
          isClearable
          isSearchable
          name="broker-re-level-select"
          onChange={handleLevelChange}
          value={selectedLevel}
          controlStyles={{ width: 200 }}
          menuStyles={{ zIndex: 100 }}
        />
      </div>
      <div className='brokers-new-input-pair'>
        <div className='brokers-new-input-label'>
          <p>Date Passed:</p>
        </div>
        <StylishDateTimePicker
          date={dateExaminationPassed}
          onSelect={() => { }}
          onChange={handleExaminationDateChange}
          icon={faCalendarCheck}
          groupStyles={{ width: 200 }}
        />
      </div>
      <div className='brokers-new-name-box align-right'>
        <FancySubmit title='Add Record' onClick={handleExaminationRecordAdd} />
      </div>
    </>
  );
}

export default ExaminationRecordForm;
