import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./stylish-date.scss";

const StylishDateTimePicker = ({
  date,
  onSelect,
  onChange,
  icon,
  dateFormat = "Pp",
  groupStyles = {},
  inputStyles = {}
}) => (
  <div className="stylish-date-group" style={{ ...groupStyles }}>
    <FontAwesomeIcon icon={icon} className="stylish-date-icon" />
    <DatePicker
      selected={date}
      onSelect={onSelect}
      onChange={onChange}
      showTimeSelect
      dateFormat={dateFormat}
      className="stylish-date-input"
      style={{ ...inputStyles }}
    />
  </div>
);

export default StylishDateTimePicker;
