import React from "react";
import "./broker-cob-item.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandsHolding, faCalendarCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const BrokerCobItem = ({ cob, index, lastItem = false, onDelete = (i) => console.log(i) }) => {
  var dofaStart = cob?.dofaStart ? new Date(cob?.dofaStart).toISOString().split('T')[0] : "";
  var containerStyle = lastItem ?
    "broker-cob-item-container broker-cob-item-container-bottom-margin" :
    "broker-cob-item-container";

  return (
    <div className={containerStyle}>
      <div className="broker-cob-item-icon-box">
        <FontAwesomeIcon icon={faHandsHolding} className="broker-cob-item-icon-style" />
      </div>
      <div className="broker-cob-item-detail-box">
        <h4>{cob?.dofaProductSubcategory ?? "Unknown Product Subcategory"}</h4>
        <div className="broker-cob-item-history-box">
          <div className="broker-cob-item-date-box">
            <FontAwesomeIcon icon={faCalendarCheck} className="broker-cob-item-date-icon-style" />
            <p>{dofaStart}</p>
          </div>
        </div>
      </div>
      <div className="broker-cob-item-close-box" onClick={() => onDelete(index)}>
        <FontAwesomeIcon icon={faTrashAlt} className="broker-cob-item-close-icon-style" />
      </div>
    </div>
  );
}

export default BrokerCobItem;