import React, { useState, useEffect } from "react";
import './popup.scss';

const Popup = ({ isLoading, children }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 500);  // for smooth transition
    }
  }, [isLoading]);

  return (
    <div className={`popup-loading-overlay ${visible ? 'visible' : ''}`}>
      <div className="popup-loader"></div>
      <div className="popup-alert-card">
        {children}
      </div>
    </div>
  );
};

export default Popup;
