import React from 'react';
import SideImageCard from '../../../../../../../components/cards/SideImageCard';
import DualColumnAdjustable from '../../../../../../../components/containers/DualColumnAdjustable';
import NumberValueCard from '../../../../../../../components/cards/NumberValueCard';
import cobImage from '../../../../../../../images/in-app/cardimages/handshake.png';
import CobRecordForm from './CobRecordForm';
import CobList from './CobList';

const CobCard = ({
  cobs,
  exams,
  qualifications,
  dofaDate,
  setCobs = () => { }
}) => {
  return (
    <SideImageCard img={cobImage} imgAlt="Cob">
      <div className='brokers-new-info-form'>
        <div className='brokers-new-name-box'>
          <h4>COBs</h4>
        </div>
        <DualColumnAdjustable
          leftFlex={1}
          leftContent={<>
            <NumberValueCard
              value={cobs?.length ?? 0}
              valueColor={cobs?.length > 0 ? '#72cc72' : '#e57373'}
              title={cobs?.length === 1 ? 'COB' : 'COBs'}
              titleColor='#7D8973'
            />
          </>}
          rightFlex={1}
          rightContent={<>
          </>}
        />
        <hr />
        <CobRecordForm
          cobs={cobs}
          exams={exams}
          qualifications={qualifications}
          dofaDate={dofaDate}
          setCobs={setCobs}
        />
        <hr />
        <CobList
          cobs={cobs}
          setCobs={setCobs}
        />
      </div>
    </SideImageCard>
  );
};

export default CobCard;