import React from "react";
import "./fancy-switch.scss"

const FancySwitch = ({ checked, onChange = () => { }, colorLeft, colorRight }) => {
  const style = {
    '--color-left': colorLeft,
    '--color-right': colorRight
  };

  return (
    <div className="checkbox-wrapper-25" style={style}>
      <input type="checkbox" checked={checked} onChange={onChange} />
    </div>
  );
};

export default FancySwitch;
