import React from "react";
import SideListContainer from '../SideListContainer';
import ItemDetailContainer from '../ItemDetailContainer';
import NoItem from '../../listitems/genericitems/NoItem';
import './main-list.scss';

const MainList = ({ loading, entities, renderEntity, noItemTitle, children }) => {
  return (
    <div className="main-list-container">
      <SideListContainer loading={loading} semitransparentbg>
        {
          entities.length > 0 ? entities.map((entity, index) => renderEntity(entity, index)) : <NoItem title={noItemTitle} />
        }
      </SideListContainer>
      <ItemDetailContainer>
        {children}
      </ItemDetailContainer>
    </div>
  );
}

export default MainList;
