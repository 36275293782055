import React from "react";
import "./left-component.scss";

const LeftComponent = ({ children }) => {
  return (
    <div className="left-component">
      {children}
    </div>
  );
};

export default LeftComponent;
