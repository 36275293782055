import { PublicRoutes } from './PublicRoutes';
import PrivateRoute from '../PrivateRoute';
import Dashboard from '../../screens/private/main/Dashboard';
import Assessments from '../../screens/private/competency/assessments/Assessments';
import AssessmentsAll from '../../screens/private/competency/assessments/AssessmentsAll';
import AssessmentsNew from '../../screens/private/competency/assessments/AssessmentsNew';
import LookupLists from '../../screens/private/competency/lists/LookupLists';
import LookupListsAll from '../../screens/private/competency/lists/LookupListsAll';
import Brokerages from '../../screens/private/management/brokerages/Brokerages';
import BrokeragesAll from '../../screens/private/management/brokerages/BrokeragesAll';
import BrokeragesNew from '../../screens/private/management/brokerages/BrokeragesNew';
import Brokers from '../../screens/private/management/brokers/Brokers';
import BrokersAll from '../../screens/private/management/brokers/BrokersAll';
import BrokersNew from '../../screens/private/management/brokers/BrokersNew';

export const FsbRoutes = [
  ...PublicRoutes,
  {
    path: "/fsp/dashboard",
    exact: true,
    element: <PrivateRoute>
      <Dashboard />
    </PrivateRoute>,
    children: [
      {
        path: "/fsp/dashboard/competency/assessments",
        exact: true,
        element: <Assessments />,
        children: [
          {
            path: "/fsp/dashboard/competency/assessments/all",
            exact: true,
            element: <AssessmentsAll />
          },
          {
            path: "/fsp/dashboard/competency/assessments/new",
            element: <AssessmentsNew />
          }
        ]
      },
      {
        path: "/fsp/dashboard/competency/lists",
        element: <LookupLists />,
        children: [
          {
            path: "/fsp/dashboard/competency/lists/all",
            element: <LookupListsAll />
          }
        ]
      },
      {
        path: "/fsp/dashboard/management/brokerages",
        element: <Brokerages />,
        children: [
          {
            path: "/fsp/dashboard/management/brokerages/all",
            element: <BrokeragesAll />
          },
          {
            path: "/fsp/dashboard/management/brokerages/new",
            element: <BrokeragesNew />
          }
        ]
      },
      {
        path: "/fsp/dashboard/management/brokers",
        element: <Brokers />,
        children: [
          {
            path: "/fsp/dashboard/management/brokers/all",
            element: <BrokersAll />
          },
          {
            path: "/fsp/dashboard/management/brokers/new",
            element: <BrokersNew />
          }
        ]
      }
    ]
  }
];