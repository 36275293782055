import React from "react";
import ListItemBase from "../../genericitems/ListItemBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScroll } from "@fortawesome/free-solid-svg-icons";

const AssessmentItem = ({ assessment, onClick = () => { } }) => {
  let nameOfBroker = "";
  let dateOfAssessment;
  
  const infoArray = assessment?.name.split(" ");
  console.log('Info Array: ', infoArray);
  if (infoArray.length === 3) {
    nameOfBroker = `${infoArray[0]} ${infoArray[1]}`;
    dateOfAssessment = new Date(Number.parseInt(infoArray[2])).toLocaleDateString('en-ZA'); // Improve this logic as a name might also have a space in
  } else if (infoArray.length === 2) {
    nameOfBroker = infoArray[0];
    dateOfAssessment = new Date(Number.parseInt(infoArray[1])).toLocaleDateString('en-ZA'); // Improve this logic as a name might also have a space in
  } else {
    nameOfBroker = assessment?.name ?? "No Name";
  }
  
  return (
    <ListItemBase
      leftComponent={<FontAwesomeIcon icon={faScroll} color="#7D8973" />}
      onClick={() => onClick(assessment)}
    >
      {dateOfAssessment ? <p>{dateOfAssessment}</p> : null}
      <p>{nameOfBroker}</p>
    </ListItemBase>
  );
}

export default AssessmentItem;
