import React from 'react';
import SideImageCard from '../../../../../../../components/cards/SideImageCard';
import NumberValueCard from '../../../../../../../components/cards/NumberValueCard';
import DualColumnAdjustable from '../../../../../../../components/containers/DualColumnAdjustable';
import qualificationsImage from '../../../../../../../images/in-app/cardimages/qualifications.png';
import QualificationRecordForm from './QualificationRecordForm';
import QualificationsList from './QualificationsList';

const QualificationsCard = ({
  qualifications,
  setQualifications = () => { }
}) => {
  return (
    <SideImageCard img={qualificationsImage} imgAlt="Qualifications">
      <div className='brokers-new-info-form'>
        <div className='brokers-new-name-box'>
          <h4>Qualifications</h4>
        </div>
        <DualColumnAdjustable
          leftFlex={1}
          leftContent={<>
            <NumberValueCard
              value={qualifications?.length ?? 0}
              valueColor={qualifications?.length > 0 ? '#72cc72' : '#e57373'}
              title={qualifications?.length === 1 ? 'Qualification' : 'Qualifications'}
              titleColor='#7D8973'
            />
          </>}
          rightFlex={1}
          rightContent={<>
          </>}
        />
        <hr />
        <QualificationRecordForm
          qualifications={qualifications}
          setQualifications={setQualifications}
        />
        <hr />
        <QualificationsList
          qualifications={qualifications}
          setQualifications={setQualifications}
        />
      </div>
    </SideImageCard>
  );
};

export default QualificationsCard;