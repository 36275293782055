import React, { useState, useEffect } from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import ApiService from '../../../../../auth/ApiService';
import { useAuth } from '../../../../../auth/AuthContext';
import MainList from '../../../../../components/containers/MainList';
import ListsItem from '../../../../../components/listitems/sidelistitems/ListsItem';
import ItemDetailWithTitle from '../../../../../components/containers/ItemDetailWithTitle';
import ListEntryItem from '../../../../../components/listitems/detailitems/ListEntryItem';
import { sortListItemsByName } from '../../../../../utils/arrays/ArrayFunctions';

const LookupListsAll = () => {
  const { sternaAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState(undefined);
  const [resetCheckboxes, setResetCheckboxes] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = sternaAuth.getToken();
        const claims = sternaAuth.parseJwt(token);
        const response = await ApiService.get('list', `userId=${claims.UserId}`);
        if (response?.data?.message === 'Success') {
          console.log(response.data.lists);
          setLists(response.data.lists);
        }
      } catch (error) {
        console.log('Error fetching lists: ', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    console.log("Selected List: ", selectedList);
  }, [selectedList]);

  const onListSelect = (list) => {
    setSelectedList(list);
    setResetCheckboxes(new Date().getTime());
  }

  return (
    <MainList
      loading={loading}
      entities={sortListItemsByName(lists)}
      noItemTitle="📭 No Lists"
      renderEntity={(list, index) => <ListsItem key={index} list={list} onClick={onListSelect} />}
    >
      <ItemDetailWithTitle
        title={selectedList?.name}
        entityDetail={selectedList}
        icon={faList}
        onDelete={() => {}}
      >
        {
          selectedList?.listItems?.length > 0 ? 
          sortListItemsByName(selectedList.listItems).map((item, index) => 
          <ListEntryItem key={index} resetCheckbox={resetCheckboxes} prefix={selectedList.name.replace(/\s+/g, '')} index={index} entry={item} />) : 
          null
        }
      </ItemDetailWithTitle>
    </MainList>
  );
}

export default LookupListsAll;
