import React from "react";
import "./calendar-item.scss";
import TopBar from "../TopBar";

const CalendarItem = ({
  month = "JAN",
  year = "1970",
  employments = [],
  gaps = [],
  qualifications = [],
  examinations = []
}) => {
  const getMonthStartAndEnd = (month, year) => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);
    return { startDate, endDate };
  };

  const monthIndex = new Date(`${month} 01 ${year}`).getMonth() + 1;
  const { startDate, endDate } = getMonthStartAndEnd(monthIndex, year);

  const calculateGapBarProperties = (gap) => {
    const itemWidth = 100;
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
    const dayWidth = itemWidth / totalDays;

    let gapStart = new Date(gap.gapStart);
    let gapEnd = new Date(gap.gapEnd);

    let offsetLeft = 0;
    let barWidth = 0;
    let roundedStart = false;
    let roundedEnd = false;

    if (gapStart < startDate) {
      gapStart = startDate;
      roundedStart = false;
    } else {
      offsetLeft = ((gapStart - startDate) / (1000 * 60 * 60 * 24)) * dayWidth;
      roundedStart = true;
    }

    if (gapEnd > endDate) {
      gapEnd = endDate;
      roundedEnd = false;
    } else {
      roundedEnd = true;
    }

    barWidth = (((gapEnd - gapStart) / (1000 * 60 * 60 * 24)) + 1) * dayWidth;

    return { offsetLeft, barWidth, roundedStart, roundedEnd };
  };

  const gapWrapperHeightFromTop = (employments.length * 40) + 20;
  const gapWrapperStyle = {
    "--distance-from-top": `${gapWrapperHeightFromTop}px`
  };

  const calculateEmploymentBarProperties = (employment) => {
    const itemWidth = 100;
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
    const dayWidth = itemWidth / totalDays;

    let gapStart = new Date(employment.dateOfAppointment);
    let gapEnd = new Date(employment.dateOfTermination);

    let offsetLeft = 0;
    let barWidth = 0;
    let roundedStart = false;
    let roundedEnd = false;

    if (gapStart < startDate) {
      gapStart = startDate;
      roundedStart = false;
    } else {
      offsetLeft = ((gapStart - startDate) / (1000 * 60 * 60 * 24)) * dayWidth;
      roundedStart = true;
    }

    if (gapEnd > endDate) {
      gapEnd = endDate;
      roundedEnd = false;
    } else {
      roundedEnd = true;
    }

    barWidth = (((gapEnd - gapStart) / (1000 * 60 * 60 * 24)) + 1) * dayWidth;

    return { offsetLeft, barWidth, roundedStart, roundedEnd };
  };

  return (
    <div className="calendar-item">
      <TopBar>
        <div className="calendar-item-title">
          <p>{`${month} ${year}`}</p>
        </div>
      </TopBar>
      <div className="calendar-box">
        {employments.map((employment, index) => {
          const employmentWrapperHeightFromTop = (index * 40) + 10;
          const employmentWrapperStyle = {
            '--distance-from-top-emp': `${employmentWrapperHeightFromTop}px`
          };

          const { offsetLeft, barWidth, roundedStart, roundedEnd } = calculateEmploymentBarProperties(employment);
          return (
            <div className="employment-bar-wrapper" key={index} style={employmentWrapperStyle}>
              <div
                className="employment-bar"
                style={{
                  marginLeft: `${offsetLeft}px`,
                  width: `${barWidth}px`,
                  borderTopLeftRadius: `${roundedStart ? '1px' : '0'}`,
                  borderBottomLeftRadius: `${roundedStart ? '1px' : '0'}`,
                  borderTopRightRadius: `${roundedEnd ? '1px' : '0'}`,
                  borderBottomRightRadius: `${roundedEnd ? '1px' : '0'}`
                }}
              ></div>
            </div>
          );
        })}
        {gaps.map((gap, index) => {
          const { offsetLeft, barWidth, roundedStart, roundedEnd } = calculateGapBarProperties(gap);
          return (
            <div className="gap-bar-wrapper" key={index} style={gapWrapperStyle}>
              <div
                className="gap-bar"
                style={{
                  marginLeft: `${offsetLeft}px`,
                  width: `${barWidth}px`,
                  borderTopLeftRadius: `${roundedStart ? '1px' : '0'}`,
                  borderBottomLeftRadius: `${roundedStart ? '1px' : '0'}`,
                  borderTopRightRadius: `${roundedEnd ? '1px' : '0'}`,
                  borderBottomRightRadius: `${roundedEnd ? '1px' : '0'}`
                }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarItem;
